import { useEffect, useState } from 'react'

export default function useCountdown(seconds: number) {
  const [countDown, setCountDown] = useState<number>(seconds);

    useEffect(() => {
        const interval = setInterval(() => {
          setCountDown(old => old - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

   return countDown;
}
