// tslint:disable
/**
 * Raksa Oy Agent
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1.0.0-rc0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

/**
 * 
 * @export
 * @interface AMLRecord
 */
export interface AMLRecord {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AMLRecord
     */
    aml?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AMLRecord
     */
    amlContext?: string;
    /**
     * 
     * @type {string}
     * @memberof AMLRecord
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface ActionMenuFetchResult
 */
export interface ActionMenuFetchResult {
    /**
     * Action menu
     * @type {Menu}
     * @memberof ActionMenuFetchResult
     */
    result?: Menu;
}
/**
 * 
 * @export
 * @interface ActionMenuModulesResult
 */
export interface ActionMenuModulesResult {
}
/**
 * 
 * @export
 * @interface AdminConfig
 */
export interface AdminConfig {
    /**
     * Configuration settings
     * @type {any}
     * @memberof AdminConfig
     */
    config?: any;
}
/**
 * 
 * @export
 * @interface AdminMediationDeny
 */
export interface AdminMediationDeny {
    /**
     * List of mediator rules for recipient
     * @type {Array<string>}
     * @memberof AdminMediationDeny
     */
    mediator_terms?: Array<string>;
    /**
     * List of recipient rules for mediation
     * @type {Array<string>}
     * @memberof AdminMediationDeny
     */
    recipient_terms?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminModules
 */
export interface AdminModules {
    /**
     * List of admin modules
     * @type {Array<string>}
     * @memberof AdminModules
     */
    result?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminReset
 */
export interface AdminReset {
}
/**
 * 
 * @export
 * @interface AdminShutdown
 */
export interface AdminShutdown {
}
/**
 * 
 * @export
 * @interface AdminStatus
 */
export interface AdminStatus {
    /**
     * Conductor statistics
     * @type {any}
     * @memberof AdminStatus
     */
    conductor?: any;
    /**
     * Default label
     * @type {string}
     * @memberof AdminStatus
     */
    label?: string;
    /**
     * Timing results
     * @type {any}
     * @memberof AdminStatus
     */
    timing?: any;
    /**
     * Version code
     * @type {string}
     * @memberof AdminStatus
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface AdminStatusLiveliness
 */
export interface AdminStatusLiveliness {
    /**
     * Liveliness status
     * @type {boolean}
     * @memberof AdminStatusLiveliness
     */
    alive?: boolean;
}
/**
 * 
 * @export
 * @interface AdminStatusReadiness
 */
export interface AdminStatusReadiness {
    /**
     * Readiness status
     * @type {boolean}
     * @memberof AdminStatusReadiness
     */
    ready?: boolean;
}
/**
 * 
 * @export
 * @interface AttachDecorator
 */
export interface AttachDecorator {
    /**
     * Attachment identifier
     * @type {string}
     * @memberof AttachDecorator
     */
    id?: string;
    /**
     * Byte count of data included by reference
     * @type {number}
     * @memberof AttachDecorator
     */
    byte_count?: number;
    /**
     * 
     * @type {AttachDecoratorData}
     * @memberof AttachDecorator
     */
    data: AttachDecoratorData;
    /**
     * Human-readable description of content
     * @type {string}
     * @memberof AttachDecorator
     */
    description?: string;
    /**
     * File name
     * @type {string}
     * @memberof AttachDecorator
     */
    filename?: string;
    /**
     * Hint regarding last modification datetime, in ISO-8601 format
     * @type {string}
     * @memberof AttachDecorator
     */
    lastmod_time?: string;
    /**
     * MIME type
     * @type {string}
     * @memberof AttachDecorator
     */
    mime_type?: string;
}
/**
 * 
 * @export
 * @interface AttachDecoratorData
 */
export interface AttachDecoratorData {
    /**
     * Base64-encoded data
     * @type {string}
     * @memberof AttachDecoratorData
     */
    base64?: string;
    /**
     * JSON-serialized data
     * @type {any}
     * @memberof AttachDecoratorData
     */
    json?: any;
    /**
     * Detached Java Web Signature
     * @type {AttachDecoratorDataJWS}
     * @memberof AttachDecoratorData
     */
    jws?: AttachDecoratorDataJWS;
    /**
     * List of hypertext links to data
     * @type {Array<string>}
     * @memberof AttachDecoratorData
     */
    links?: Array<string>;
    /**
     * SHA256 hash (binhex encoded) of content
     * @type {string}
     * @memberof AttachDecoratorData
     */
    sha256?: string;
}
/**
 * 
 * @export
 * @interface AttachDecoratorData1JWS
 */
export interface AttachDecoratorData1JWS {
    /**
     * 
     * @type {AttachDecoratorDataJWSHeader}
     * @memberof AttachDecoratorData1JWS
     */
    header: AttachDecoratorDataJWSHeader;
    /**
     * protected JWS header
     * @type {string}
     * @memberof AttachDecoratorData1JWS
     */
    _protected?: string;
    /**
     * signature
     * @type {string}
     * @memberof AttachDecoratorData1JWS
     */
    signature: string;
}
/**
 * 
 * @export
 * @interface AttachDecoratorDataJWS
 */
export interface AttachDecoratorDataJWS {
    /**
     * 
     * @type {AttachDecoratorDataJWSHeader}
     * @memberof AttachDecoratorDataJWS
     */
    header?: AttachDecoratorDataJWSHeader;
    /**
     * protected JWS header
     * @type {string}
     * @memberof AttachDecoratorDataJWS
     */
    _protected?: string;
    /**
     * signature
     * @type {string}
     * @memberof AttachDecoratorDataJWS
     */
    signature?: string;
    /**
     * List of signatures
     * @type {Array<AttachDecoratorData1JWS>}
     * @memberof AttachDecoratorDataJWS
     */
    signatures?: Array<AttachDecoratorData1JWS>;
}
/**
 * 
 * @export
 * @interface AttachDecoratorDataJWSHeader
 */
export interface AttachDecoratorDataJWSHeader {
    /**
     * Key identifier, in W3C did:key or DID URL format
     * @type {string}
     * @memberof AttachDecoratorDataJWSHeader
     */
    kid: string;
}
/**
 * 
 * @export
 * @interface AttachmentDef
 */
export interface AttachmentDef {
    /**
     * Attachment identifier
     * @type {string}
     * @memberof AttachmentDef
     */
    id?: string;
    /**
     * Attachment type
     * @type {string}
     * @memberof AttachmentDef
     */
    type?: AttachmentDef.TypeEnum;
}

/**
 * @export
 * @namespace AttachmentDef
 */
export namespace AttachmentDef {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        CredentialOffer = <any> 'credential-offer',
        PresentProof = <any> 'present-proof'
    }
}
/**
 * 
 * @export
 * @interface AttributeMimeTypesResult
 */
export interface AttributeMimeTypesResult {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AttributeMimeTypesResult
     */
    results?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface BasicMessageModuleResponse
 */
export interface BasicMessageModuleResponse {
}
/**
 * 
 * @export
 * @interface ClaimFormat
 */
export interface ClaimFormat {
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    jwt?: any;
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    jwt_vc?: any;
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    jwt_vp?: any;
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    ldp?: any;
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    ldp_vc?: any;
    /**
     * 
     * @type {any}
     * @memberof ClaimFormat
     */
    ldp_vp?: any;
}
/**
 * 
 * @export
 * @interface ClearPendingRevocationsRequest
 */
export interface ClearPendingRevocationsRequest {
    /**
     * Credential revocation ids by revocation registry id: omit for all, specify null or empty list for all pending per revocation registry
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ClearPendingRevocationsRequest
     */
    purge?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ConnRecord
 */
export interface ConnRecord {
    /**
     * Connection acceptance: manual or auto
     * @type {string}
     * @memberof ConnRecord
     */
    accept?: ConnRecord.AcceptEnum;
    /**
     * Optional alias to apply to connection for later use
     * @type {string}
     * @memberof ConnRecord
     */
    alias?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof ConnRecord
     */
    connection_id?: string;
    /**
     * Connection protocol used
     * @type {string}
     * @memberof ConnRecord
     */
    connection_protocol?: ConnRecord.ConnectionProtocolEnum;
    /**
     * Time of record creation
     * @type {string}
     * @memberof ConnRecord
     */
    created_at?: string;
    /**
     * Error message
     * @type {string}
     * @memberof ConnRecord
     */
    error_msg?: string;
    /**
     * Inbound routing connection id to use
     * @type {string}
     * @memberof ConnRecord
     */
    inbound_connection_id?: string;
    /**
     * Public key for connection
     * @type {string}
     * @memberof ConnRecord
     */
    invitation_key?: string;
    /**
     * Invitation mode
     * @type {string}
     * @memberof ConnRecord
     */
    invitation_mode?: ConnRecord.InvitationModeEnum;
    /**
     * ID of out-of-band invitation message
     * @type {string}
     * @memberof ConnRecord
     */
    invitation_msg_id?: string;
    /**
     * Our DID for connection
     * @type {string}
     * @memberof ConnRecord
     */
    my_did?: string;
    /**
     * Connection request identifier
     * @type {string}
     * @memberof ConnRecord
     */
    request_id?: string;
    /**
     * State per RFC 23
     * @type {string}
     * @memberof ConnRecord
     */
    rfc23_state?: string;
    /**
     * Routing state of connection
     * @type {string}
     * @memberof ConnRecord
     */
    routing_state?: ConnRecord.RoutingStateEnum;
    /**
     * Current record state
     * @type {string}
     * @memberof ConnRecord
     */
    state?: string;
    /**
     * Their DID for connection
     * @type {string}
     * @memberof ConnRecord
     */
    their_did?: string;
    /**
     * Their label for connection
     * @type {string}
     * @memberof ConnRecord
     */
    their_label?: string;
    /**
     * Other agent's public DID for connection
     * @type {string}
     * @memberof ConnRecord
     */
    their_public_did?: string;
    /**
     * Their role in the connection protocol
     * @type {string}
     * @memberof ConnRecord
     */
    their_role?: ConnRecord.TheirRoleEnum;
    /**
     * Time of last record update
     * @type {string}
     * @memberof ConnRecord
     */
    updated_at?: string;
}

/**
 * @export
 * @namespace ConnRecord
 */
export namespace ConnRecord {
    /**
     * @export
     * @enum {string}
     */
    export enum AcceptEnum {
        Manual = <any> 'manual',
        Auto = <any> 'auto'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ConnectionProtocolEnum {
        Connections10 = <any> 'connections/1.0',
        Didexchange10 = <any> 'didexchange/1.0'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum InvitationModeEnum {
        Once = <any> 'once',
        Multi = <any> 'multi',
        Static = <any> 'static'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoutingStateEnum {
        None = <any> 'none',
        Request = <any> 'request',
        Active = <any> 'active',
        Error = <any> 'error'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TheirRoleEnum {
        Invitee = <any> 'invitee',
        Requester = <any> 'requester',
        Inviter = <any> 'inviter',
        Responder = <any> 'responder'
    }
}
/**
 * 
 * @export
 * @interface ConnectionInvitation
 */
export interface ConnectionInvitation {
    /**
     * Message identifier
     * @type {string}
     * @memberof ConnectionInvitation
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof ConnectionInvitation
     */
    type?: string;
    /**
     * DID for connection invitation
     * @type {string}
     * @memberof ConnectionInvitation
     */
    did?: string;
    /**
     * Optional image URL for connection invitation
     * @type {string}
     * @memberof ConnectionInvitation
     */
    imageUrl?: string;
    /**
     * Optional label for connection invitation
     * @type {string}
     * @memberof ConnectionInvitation
     */
    label?: string;
    /**
     * List of recipient keys
     * @type {Array<string>}
     * @memberof ConnectionInvitation
     */
    recipientKeys?: Array<string>;
    /**
     * List of routing keys
     * @type {Array<string>}
     * @memberof ConnectionInvitation
     */
    routingKeys?: Array<string>;
    /**
     * Service endpoint at which to reach this agent
     * @type {string}
     * @memberof ConnectionInvitation
     */
    serviceEndpoint?: string;
}
/**
 * 
 * @export
 * @interface ConnectionList
 */
export interface ConnectionList {
    /**
     * List of connection records
     * @type {Array<ConnRecord>}
     * @memberof ConnectionList
     */
    results?: Array<ConnRecord>;
}
/**
 * 
 * @export
 * @interface ConnectionMetadata
 */
export interface ConnectionMetadata {
    /**
     * Dictionary of metadata associated with connection.
     * @type {any}
     * @memberof ConnectionMetadata
     */
    results?: any;
}
/**
 * 
 * @export
 * @interface ConnectionMetadataSetRequest
 */
export interface ConnectionMetadataSetRequest {
    /**
     * Dictionary of metadata to set for connection.
     * @type {any}
     * @memberof ConnectionMetadataSetRequest
     */
    metadata: any;
}
/**
 * 
 * @export
 * @interface ConnectionModuleResponse
 */
export interface ConnectionModuleResponse {
}
/**
 * 
 * @export
 * @interface ConnectionStaticRequest
 */
export interface ConnectionStaticRequest {
    /**
     * Alias to assign to this connection
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    alias?: string;
    /**
     * Local DID
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    my_did?: string;
    /**
     * Seed to use for the local DID
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    my_seed?: string;
    /**
     * Remote DID
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    their_did?: string;
    /**
     * URL endpoint for other party
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    their_endpoint?: string;
    /**
     * Other party's label for this connection
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    their_label?: string;
    /**
     * Seed to use for the remote DID
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    their_seed?: string;
    /**
     * Remote verification key
     * @type {string}
     * @memberof ConnectionStaticRequest
     */
    their_verkey?: string;
}
/**
 * 
 * @export
 * @interface ConnectionStaticResult
 */
export interface ConnectionStaticResult {
    /**
     * Local DID
     * @type {string}
     * @memberof ConnectionStaticResult
     */
    my_did: string;
    /**
     * My URL endpoint
     * @type {string}
     * @memberof ConnectionStaticResult
     */
    my_endpoint: string;
    /**
     * My verification key
     * @type {string}
     * @memberof ConnectionStaticResult
     */
    my_verkey: string;
    /**
     * 
     * @type {ConnRecord}
     * @memberof ConnectionStaticResult
     */
    record: ConnRecord;
    /**
     * Remote DID
     * @type {string}
     * @memberof ConnectionStaticResult
     */
    their_did: string;
    /**
     * Remote verification key
     * @type {string}
     * @memberof ConnectionStaticResult
     */
    their_verkey: string;
}
/**
 * 
 * @export
 * @interface Constraints
 */
export interface Constraints {
    /**
     * 
     * @type {Array<DIFField>}
     * @memberof Constraints
     */
    fields?: Array<DIFField>;
    /**
     * 
     * @type {Array<DIFHolder>}
     * @memberof Constraints
     */
    is_holder?: Array<DIFHolder>;
    /**
     * LimitDisclosure
     * @type {string}
     * @memberof Constraints
     */
    limit_disclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof Constraints
     */
    status_active?: Constraints.StatusActiveEnum;
    /**
     * 
     * @type {string}
     * @memberof Constraints
     */
    status_revoked?: Constraints.StatusRevokedEnum;
    /**
     * 
     * @type {string}
     * @memberof Constraints
     */
    status_suspended?: Constraints.StatusSuspendedEnum;
    /**
     * SubjectIsIssuer
     * @type {string}
     * @memberof Constraints
     */
    subject_is_issuer?: Constraints.SubjectIsIssuerEnum;
}

/**
 * @export
 * @namespace Constraints
 */
export namespace Constraints {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusActiveEnum {
        Required = <any> 'required',
        Allowed = <any> 'allowed',
        Disallowed = <any> 'disallowed'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusRevokedEnum {
        Required = <any> 'required',
        Allowed = <any> 'allowed',
        Disallowed = <any> 'disallowed'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusSuspendedEnum {
        Required = <any> 'required',
        Allowed = <any> 'allowed',
        Disallowed = <any> 'disallowed'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SubjectIsIssuerEnum {
        Required = <any> 'required',
        Preferred = <any> 'preferred'
    }
}
/**
 * 
 * @export
 * @interface CreateInvitationRequest
 */
export interface CreateInvitationRequest {
    /**
     * Identifier for active mediation record to be used
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    mediation_id?: string;
    /**
     * Optional metadata to attach to the connection created with the invitation
     * @type {any}
     * @memberof CreateInvitationRequest
     */
    metadata?: any;
    /**
     * Optional label for connection invitation
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    my_label?: string;
    /**
     * List of recipient keys
     * @type {Array<string>}
     * @memberof CreateInvitationRequest
     */
    recipient_keys?: Array<string>;
    /**
     * List of routing keys
     * @type {Array<string>}
     * @memberof CreateInvitationRequest
     */
    routing_keys?: Array<string>;
    /**
     * Connection endpoint
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    service_endpoint?: string;
}
/**
 * 
 * @export
 * @interface CredAttrSpec
 */
export interface CredAttrSpec {
    /**
     * MIME type: omit for (null) default
     * @type {string}
     * @memberof CredAttrSpec
     */
    mime_type?: string;
    /**
     * Attribute name
     * @type {string}
     * @memberof CredAttrSpec
     */
    name: string;
    /**
     * Attribute value: base64-encode if MIME type is present
     * @type {string}
     * @memberof CredAttrSpec
     */
    value: string;
}
/**
 * 
 * @export
 * @interface CredDefValue
 */
export interface CredDefValue {
    /**
     * Primary value for credential definition
     * @type {CredDefValuePrimary}
     * @memberof CredDefValue
     */
    primary?: CredDefValuePrimary;
    /**
     * Revocation value for credential definition
     * @type {CredDefValueRevocation}
     * @memberof CredDefValue
     */
    revocation?: CredDefValueRevocation;
}
/**
 * 
 * @export
 * @interface CredDefValuePrimary
 */
export interface CredDefValuePrimary {
    /**
     * 
     * @type {string}
     * @memberof CredDefValuePrimary
     */
    n?: string;
    /**
     * 
     * @type {Generated}
     * @memberof CredDefValuePrimary
     */
    r?: Generated;
    /**
     * 
     * @type {string}
     * @memberof CredDefValuePrimary
     */
    rctxt?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValuePrimary
     */
    s?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValuePrimary
     */
    z?: string;
}
/**
 * 
 * @export
 * @interface CredDefValueRevocation
 */
export interface CredDefValueRevocation {
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    g?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    g_dash?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    h?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    h0?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    h1?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    h2?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    h_cap?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    htilde?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    pk?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    u?: string;
    /**
     * 
     * @type {string}
     * @memberof CredDefValueRevocation
     */
    y?: string;
}
/**
 * 
 * @export
 * @interface CredInfoList
 */
export interface CredInfoList {
    /**
     * 
     * @type {Array<IndyCredInfo>}
     * @memberof CredInfoList
     */
    results?: Array<IndyCredInfo>;
}
/**
 * 
 * @export
 * @interface CredRevIndyRecordsResult
 */
export interface CredRevIndyRecordsResult {
    /**
     * Indy revocation registry delta
     * @type {any}
     * @memberof CredRevIndyRecordsResult
     */
    rev_reg_delta?: any;
}
/**
 * 
 * @export
 * @interface CredRevRecordDetailsResult
 */
export interface CredRevRecordDetailsResult {
    /**
     * 
     * @type {Array<IssuerCredRevRecord>}
     * @memberof CredRevRecordDetailsResult
     */
    results?: Array<IssuerCredRevRecord>;
}
/**
 * 
 * @export
 * @interface CredRevRecordResult
 */
export interface CredRevRecordResult {
    /**
     * 
     * @type {IssuerCredRevRecord}
     * @memberof CredRevRecordResult
     */
    result?: IssuerCredRevRecord;
}
/**
 * 
 * @export
 * @interface CredRevokedResult
 */
export interface CredRevokedResult {
    /**
     * Whether credential is revoked on the ledger
     * @type {boolean}
     * @memberof CredRevokedResult
     */
    revoked?: boolean;
}
/**
 * 
 * @export
 * @interface Credential
 */
export interface Credential {
    /**
     * The JSON-LD context of the credential
     * @type {Array<any>}
     * @memberof Credential
     */
    context: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof Credential
     */
    credentialSubject: any;
    /**
     * The expiration date
     * @type {string}
     * @memberof Credential
     */
    expirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    id?: string;
    /**
     * The issuance date
     * @type {string}
     * @memberof Credential
     */
    issuanceDate: string;
    /**
     * The JSON-LD Verifiable Credential Issuer. Either string of object with id field.
     * @type {any}
     * @memberof Credential
     */
    issuer: any;
    /**
     * The proof of the credential
     * @type {LinkedDataProof}
     * @memberof Credential
     */
    proof?: LinkedDataProof;
    /**
     * The JSON-LD type of the credential
     * @type {Array<string>}
     * @memberof Credential
     */
    type: Array<string>;
}
/**
 * 
 * @export
 * @interface CredentialDefinition
 */
export interface CredentialDefinition {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof CredentialDefinition
     */
    id?: string;
    /**
     * Schema identifier within credential definition identifier
     * @type {string}
     * @memberof CredentialDefinition
     */
    schemaId?: string;
    /**
     * Tag within credential definition identifier
     * @type {string}
     * @memberof CredentialDefinition
     */
    tag?: string;
    /**
     * Signature type: CL for Camenisch-Lysyanskaya
     * @type {any}
     * @memberof CredentialDefinition
     */
    type?: any;
    /**
     * Credential definition primary and revocation values
     * @type {CredDefValue}
     * @memberof CredentialDefinition
     */
    value?: CredDefValue;
    /**
     * Node protocol version
     * @type {string}
     * @memberof CredentialDefinition
     */
    ver?: string;
}
/**
 * 
 * @export
 * @interface CredentialDefinitionGetResult
 */
export interface CredentialDefinitionGetResult {
    /**
     * 
     * @type {CredentialDefinition}
     * @memberof CredentialDefinitionGetResult
     */
    credential_definition?: CredentialDefinition;
}
/**
 * 
 * @export
 * @interface CredentialDefinitionSendRequest
 */
export interface CredentialDefinitionSendRequest {
    /**
     * Revocation registry size
     * @type {number}
     * @memberof CredentialDefinitionSendRequest
     */
    revocation_registry_size?: number;
    /**
     * Schema identifier
     * @type {string}
     * @memberof CredentialDefinitionSendRequest
     */
    schema_id?: string;
    /**
     * Revocation supported flag
     * @type {boolean}
     * @memberof CredentialDefinitionSendRequest
     */
    support_revocation?: boolean;
    /**
     * Credential definition identifier tag
     * @type {string}
     * @memberof CredentialDefinitionSendRequest
     */
    tag?: string;
}
/**
 * 
 * @export
 * @interface CredentialDefinitionSendResult
 */
export interface CredentialDefinitionSendResult {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof CredentialDefinitionSendResult
     */
    credential_definition_id?: string;
}
/**
 * 
 * @export
 * @interface CredentialDefinitionsCreatedResult
 */
export interface CredentialDefinitionsCreatedResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof CredentialDefinitionsCreatedResult
     */
    credential_definition_ids?: Array<string>;
}
/**
 * 
 * @export
 * @interface CredentialOffer
 */
export interface CredentialOffer {
    /**
     * Message identifier
     * @type {string}
     * @memberof CredentialOffer
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof CredentialOffer
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof CredentialOffer
     */
    comment?: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof CredentialOffer
     */
    credential_preview?: CredentialPreview;
    /**
     * 
     * @type {Array<AttachDecorator>}
     * @memberof CredentialOffer
     */
    offersattach: Array<AttachDecorator>;
}
/**
 * 
 * @export
 * @interface CredentialPreview
 */
export interface CredentialPreview {
    /**
     * Message type identifier
     * @type {string}
     * @memberof CredentialPreview
     */
    type?: string;
    /**
     * 
     * @type {Array<CredAttrSpec>}
     * @memberof CredentialPreview
     */
    attributes: Array<CredAttrSpec>;
}
/**
 * 
 * @export
 * @interface CredentialProposal
 */
export interface CredentialProposal {
    /**
     * Message identifier
     * @type {string}
     * @memberof CredentialProposal
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof CredentialProposal
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof CredentialProposal
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    cred_def_id?: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof CredentialProposal
     */
    credential_proposal?: CredentialPreview;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    issuer_did?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    schema_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    schema_issuer_did?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    schema_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialProposal
     */
    schema_version?: string;
}
/**
 * 
 * @export
 * @interface CredentialStatusOptions
 */
export interface CredentialStatusOptions {
    /**
     * Credential status method type to use for the credential. Should match status method registered in the Verifiable Credential Extension Registry
     * @type {string}
     * @memberof CredentialStatusOptions
     */
    type: string;
}
/**
 * 
 * @export
 * @interface DID
 */
export interface DID {
    /**
     * DID of interest
     * @type {string}
     * @memberof DID
     */
    did?: string;
    /**
     * Key type associated with the DID
     * @type {string}
     * @memberof DID
     */
    key_type?: DID.KeyTypeEnum;
    /**
     * Did method associated with the DID
     * @type {string}
     * @memberof DID
     */
    method?: DID.MethodEnum;
    /**
     * Whether DID is current public DID, posted to ledger but not current public DID, or local to the wallet
     * @type {string}
     * @memberof DID
     */
    posture?: DID.PostureEnum;
    /**
     * Public verification key
     * @type {string}
     * @memberof DID
     */
    verkey?: string;
}

/**
 * @export
 * @namespace DID
 */
export namespace DID {
    /**
     * @export
     * @enum {string}
     */
    export enum KeyTypeEnum {
        Ed25519 = <any> 'ed25519',
        Bls12381g2 = <any> 'bls12381g2'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Sov = <any> 'sov',
        Key = <any> 'key'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PostureEnum {
        Public = <any> 'public',
        Posted = <any> 'posted',
        WalletOnly = <any> 'wallet_only'
    }
}
/**
 * 
 * @export
 * @interface DIDCreate
 */
export interface DIDCreate {
    /**
     * 
     * @type {string}
     * @memberof DIDCreate
     */
    method?: DIDCreate.MethodEnum;
    /**
     * To define a key type for a did:key
     * @type {DIDCreateOptions}
     * @memberof DIDCreate
     */
    options?: DIDCreateOptions;
    /**
     * Optional seed to use for DID, Must beenabled in configuration before use.
     * @type {string}
     * @memberof DIDCreate
     */
    seed?: string;
}

/**
 * @export
 * @namespace DIDCreate
 */
export namespace DIDCreate {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Key = <any> 'key',
        Sov = <any> 'sov'
    }
}
/**
 * 
 * @export
 * @interface DIDCreateOptions
 */
export interface DIDCreateOptions {
    /**
     * 
     * @type {string}
     * @memberof DIDCreateOptions
     */
    key_type: DIDCreateOptions.KeyTypeEnum;
}

/**
 * @export
 * @namespace DIDCreateOptions
 */
export namespace DIDCreateOptions {
    /**
     * @export
     * @enum {string}
     */
    export enum KeyTypeEnum {
        Ed25519 = <any> 'ed25519',
        Bls12381g2 = <any> 'bls12381g2'
    }
}
/**
 * 
 * @export
 * @interface DIDEndpoint
 */
export interface DIDEndpoint {
    /**
     * DID of interest
     * @type {string}
     * @memberof DIDEndpoint
     */
    did: string;
    /**
     * Endpoint to set (omit to delete)
     * @type {string}
     * @memberof DIDEndpoint
     */
    endpoint?: string;
}
/**
 * 
 * @export
 * @interface DIDEndpointWithType
 */
export interface DIDEndpointWithType {
    /**
     * DID of interest
     * @type {string}
     * @memberof DIDEndpointWithType
     */
    did: string;
    /**
     * Endpoint to set (omit to delete)
     * @type {string}
     * @memberof DIDEndpointWithType
     */
    endpoint?: string;
    /**
     * Endpoint type to set (default 'Endpoint'); affects only public or posted DIDs
     * @type {string}
     * @memberof DIDEndpointWithType
     */
    endpoint_type?: DIDEndpointWithType.EndpointTypeEnum;
}

/**
 * @export
 * @namespace DIDEndpointWithType
 */
export namespace DIDEndpointWithType {
    /**
     * @export
     * @enum {string}
     */
    export enum EndpointTypeEnum {
        Endpoint = <any> 'Endpoint',
        Profile = <any> 'Profile',
        LinkedDomains = <any> 'LinkedDomains'
    }
}
/**
 * 
 * @export
 * @interface DIDList
 */
export interface DIDList {
    /**
     * DID list
     * @type {Array<DID>}
     * @memberof DIDList
     */
    results?: Array<DID>;
}
/**
 * 
 * @export
 * @interface DIDResult
 */
export interface DIDResult {
    /**
     * 
     * @type {DID}
     * @memberof DIDResult
     */
    result?: DID;
}
/**
 * 
 * @export
 * @interface DIDXRequest
 */
export interface DIDXRequest {
    /**
     * Message identifier
     * @type {string}
     * @memberof DIDXRequest
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof DIDXRequest
     */
    type?: string;
    /**
     * DID of exchange
     * @type {string}
     * @memberof DIDXRequest
     */
    did?: string;
    /**
     * As signed attachment, DID Doc associated with DID
     * @type {AttachDecorator}
     * @memberof DIDXRequest
     */
    did_docattach?: AttachDecorator;
    /**
     * Label for DID exchange request
     * @type {string}
     * @memberof DIDXRequest
     */
    label: string;
}
/**
 * 
 * @export
 * @interface DIFField
 */
export interface DIFField {
    /**
     * 
     * @type {Filter}
     * @memberof DIFField
     */
    filter?: Filter;
    /**
     * ID
     * @type {string}
     * @memberof DIFField
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DIFField
     */
    path?: Array<string>;
    /**
     * Preference
     * @type {string}
     * @memberof DIFField
     */
    predicate?: DIFField.PredicateEnum;
    /**
     * Purpose
     * @type {string}
     * @memberof DIFField
     */
    purpose?: string;
}

/**
 * @export
 * @namespace DIFField
 */
export namespace DIFField {
    /**
     * @export
     * @enum {string}
     */
    export enum PredicateEnum {
        Required = <any> 'required',
        Preferred = <any> 'preferred'
    }
}
/**
 * 
 * @export
 * @interface DIFHolder
 */
export interface DIFHolder {
    /**
     * Preference
     * @type {string}
     * @memberof DIFHolder
     */
    directive?: DIFHolder.DirectiveEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DIFHolder
     */
    field_id?: Array<string>;
}

/**
 * @export
 * @namespace DIFHolder
 */
export namespace DIFHolder {
    /**
     * @export
     * @enum {string}
     */
    export enum DirectiveEnum {
        Required = <any> 'required',
        Preferred = <any> 'preferred'
    }
}
/**
 * 
 * @export
 * @interface DIFOptions
 */
export interface DIFOptions {
    /**
     * Challenge protect against replay attack
     * @type {string}
     * @memberof DIFOptions
     */
    challenge?: string;
    /**
     * Domain protect against replay attack
     * @type {string}
     * @memberof DIFOptions
     */
    domain?: string;
}
/**
 * 
 * @export
 * @interface DIFPresSpec
 */
export interface DIFPresSpec {
    /**
     * Issuer identifier to sign the presentation, if different from current public DID
     * @type {string}
     * @memberof DIFPresSpec
     */
    issuer_id?: string;
    /**
     * 
     * @type {PresentationDefinition}
     * @memberof DIFPresSpec
     */
    presentation_definition?: PresentationDefinition;
    /**
     * Mapping of input_descriptor id to list of stored W3C credential record_id
     * @type {any}
     * @memberof DIFPresSpec
     */
    record_ids?: any;
    /**
     * reveal doc [JSON-LD frame] dict used to derive the credential when selective disclosure is required
     * @type {any}
     * @memberof DIFPresSpec
     */
    reveal_doc?: any;
}
/**
 * 
 * @export
 * @interface DIFProofProposal
 */
export interface DIFProofProposal {
    /**
     * 
     * @type {Array<InputDescriptors>}
     * @memberof DIFProofProposal
     */
    input_descriptors?: Array<InputDescriptors>;
    /**
     * 
     * @type {DIFOptions}
     * @memberof DIFProofProposal
     */
    options?: DIFOptions;
}
/**
 * 
 * @export
 * @interface DIFProofRequest
 */
export interface DIFProofRequest {
    /**
     * 
     * @type {DIFOptions}
     * @memberof DIFProofRequest
     */
    options?: DIFOptions;
    /**
     * 
     * @type {PresentationDefinition}
     * @memberof DIFProofRequest
     */
    presentation_definition: PresentationDefinition;
}
/**
 * 
 * @export
 * @interface Disclose
 */
export interface Disclose {
    /**
     * Message identifier
     * @type {string}
     * @memberof Disclose
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof Disclose
     */
    type?: string;
    /**
     * List of protocol descriptors
     * @type {Array<ProtocolDescriptor>}
     * @memberof Disclose
     */
    protocols: Array<ProtocolDescriptor>;
}
/**
 * 
 * @export
 * @interface Disclosures
 */
export interface Disclosures {
    /**
     * Message identifier
     * @type {string}
     * @memberof Disclosures
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof Disclosures
     */
    type?: string;
    /**
     * List of protocol or goal_code descriptors
     * @type {Array<any>}
     * @memberof Disclosures
     */
    disclosures: Array<any>;
}
/**
 * 
 * @export
 * @interface Doc
 */
export interface Doc {
    /**
     * Credential to sign
     * @type {any}
     * @memberof Doc
     */
    credential: any;
    /**
     * Signature options
     * @type {SignatureOptions}
     * @memberof Doc
     */
    options: SignatureOptions;
}
/**
 * 
 * @export
 * @interface EndorserInfo
 */
export interface EndorserInfo {
    /**
     * Endorser DID
     * @type {string}
     * @memberof EndorserInfo
     */
    endorser_did: string;
    /**
     * Endorser Name
     * @type {string}
     * @memberof EndorserInfo
     */
    endorser_name?: string;
}
/**
 * 
 * @export
 * @interface EndpointsResult
 */
export interface EndpointsResult {
    /**
     * My endpoint
     * @type {string}
     * @memberof EndpointsResult
     */
    my_endpoint?: string;
    /**
     * Their endpoint
     * @type {string}
     * @memberof EndpointsResult
     */
    their_endpoint?: string;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * Const
     * @type {any}
     * @memberof Filter
     */
    _const?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof Filter
     */
    _enum?: Array<any>;
    /**
     * ExclusiveMaximum
     * @type {any}
     * @memberof Filter
     */
    exclusiveMaximum?: any;
    /**
     * ExclusiveMinimum
     * @type {any}
     * @memberof Filter
     */
    exclusiveMinimum?: any;
    /**
     * Format
     * @type {string}
     * @memberof Filter
     */
    format?: string;
    /**
     * Max Length
     * @type {number}
     * @memberof Filter
     */
    maxLength?: number;
    /**
     * Maximum
     * @type {any}
     * @memberof Filter
     */
    maximum?: any;
    /**
     * Min Length
     * @type {number}
     * @memberof Filter
     */
    minLength?: number;
    /**
     * Minimum
     * @type {any}
     * @memberof Filter
     */
    minimum?: any;
    /**
     * Not
     * @type {boolean}
     * @memberof Filter
     */
    not?: boolean;
    /**
     * Pattern
     * @type {string}
     * @memberof Filter
     */
    pattern?: string;
    /**
     * Type
     * @type {string}
     * @memberof Filter
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Generated
 */
export interface Generated {
    /**
     * 
     * @type {string}
     * @memberof Generated
     */
    master_secret?: string;
    /**
     * 
     * @type {string}
     * @memberof Generated
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Generated
     */
    remainder?: string;
}
/**
 * 
 * @export
 * @interface GetDIDEndpointResponse
 */
export interface GetDIDEndpointResponse {
    /**
     * Full verification key
     * @type {string}
     * @memberof GetDIDEndpointResponse
     */
    endpoint?: string;
}
/**
 * 
 * @export
 * @interface GetDIDVerkeyResponse
 */
export interface GetDIDVerkeyResponse {
    /**
     * Full verification key
     * @type {string}
     * @memberof GetDIDVerkeyResponse
     */
    verkey?: string;
}
/**
 * 
 * @export
 * @interface GetNymRoleResponse
 */
export interface GetNymRoleResponse {
    /**
     * Ledger role
     * @type {string}
     * @memberof GetNymRoleResponse
     */
    role?: GetNymRoleResponse.RoleEnum;
}

/**
 * @export
 * @namespace GetNymRoleResponse
 */
export namespace GetNymRoleResponse {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        STEWARD = <any> 'STEWARD',
        TRUSTEE = <any> 'TRUSTEE',
        ENDORSER = <any> 'ENDORSER',
        NETWORKMONITOR = <any> 'NETWORK_MONITOR',
        USER = <any> 'USER',
        ROLEREMOVE = <any> 'ROLE_REMOVE'
    }
}
/**
 * 
 * @export
 * @interface HolderModuleResponse
 */
export interface HolderModuleResponse {
}
/**
 * 
 * @export
 * @interface IndyAttrValue
 */
export interface IndyAttrValue {
    /**
     * Attribute encoded value
     * @type {string}
     * @memberof IndyAttrValue
     */
    encoded: string;
    /**
     * Attribute raw value
     * @type {string}
     * @memberof IndyAttrValue
     */
    raw: string;
}
/**
 * 
 * @export
 * @interface IndyCredAbstract
 */
export interface IndyCredAbstract {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyCredAbstract
     */
    cred_def_id: string;
    /**
     * Key correctness proof
     * @type {IndyKeyCorrectnessProof}
     * @memberof IndyCredAbstract
     */
    key_correctness_proof: IndyKeyCorrectnessProof;
    /**
     * Nonce in credential abstract
     * @type {string}
     * @memberof IndyCredAbstract
     */
    nonce: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof IndyCredAbstract
     */
    schema_id: string;
}
/**
 * 
 * @export
 * @interface IndyCredInfo
 */
export interface IndyCredInfo {
    /**
     * Attribute names and value
     * @type {{ [key: string]: string; }}
     * @memberof IndyCredInfo
     */
    attrs?: { [key: string]: string; };
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyCredInfo
     */
    cred_def_id?: string;
    /**
     * Credential revocation identifier
     * @type {string}
     * @memberof IndyCredInfo
     */
    cred_rev_id?: string;
    /**
     * Wallet referent
     * @type {string}
     * @memberof IndyCredInfo
     */
    referent?: string;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof IndyCredInfo
     */
    rev_reg_id?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof IndyCredInfo
     */
    schema_id?: string;
}
/**
 * 
 * @export
 * @interface IndyCredPrecis
 */
export interface IndyCredPrecis {
    /**
     * Credential info
     * @type {IndyCredInfo}
     * @memberof IndyCredPrecis
     */
    cred_info?: IndyCredInfo;
    /**
     * Non-revocation interval from presentation request
     * @type {IndyNonRevocationInterval}
     * @memberof IndyCredPrecis
     */
    interval?: IndyNonRevocationInterval;
    /**
     * 
     * @type {Array<string>}
     * @memberof IndyCredPrecis
     */
    presentation_referents?: Array<string>;
}
/**
 * 
 * @export
 * @interface IndyCredRequest
 */
export interface IndyCredRequest {
    /**
     * Blinded master secret
     * @type {any}
     * @memberof IndyCredRequest
     */
    blinded_ms: any;
    /**
     * Blinded master secret correctness proof
     * @type {any}
     * @memberof IndyCredRequest
     */
    blinded_ms_correctness_proof: any;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyCredRequest
     */
    cred_def_id: string;
    /**
     * Nonce in credential request
     * @type {string}
     * @memberof IndyCredRequest
     */
    nonce: string;
    /**
     * Prover DID
     * @type {string}
     * @memberof IndyCredRequest
     */
    prover_did?: string;
}
/**
 * 
 * @export
 * @interface IndyCredential
 */
export interface IndyCredential {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyCredential
     */
    cred_def_id: string;
    /**
     * Revocation registry state
     * @type {any}
     * @memberof IndyCredential
     */
    rev_reg?: any;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof IndyCredential
     */
    rev_reg_id?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof IndyCredential
     */
    schema_id: string;
    /**
     * Credential signature
     * @type {any}
     * @memberof IndyCredential
     */
    signature: any;
    /**
     * Credential signature correctness proof
     * @type {any}
     * @memberof IndyCredential
     */
    signature_correctness_proof: any;
    /**
     * Credential attributes
     * @type {{ [key: string]: any; }}
     * @memberof IndyCredential
     */
    values: { [key: string]: any; };
    /**
     * Witness for revocation proof
     * @type {any}
     * @memberof IndyCredential
     */
    witness?: any;
}
/**
 * 
 * @export
 * @interface IndyEQProof
 */
export interface IndyEQProof {
    /**
     * 
     * @type {string}
     * @memberof IndyEQProof
     */
    a_prime?: string;
    /**
     * 
     * @type {string}
     * @memberof IndyEQProof
     */
    e?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyEQProof
     */
    m?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof IndyEQProof
     */
    m2?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyEQProof
     */
    revealed_attrs?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof IndyEQProof
     */
    v?: string;
}
/**
 * 
 * @export
 * @interface IndyGEProof
 */
export interface IndyGEProof {
    /**
     * 
     * @type {string}
     * @memberof IndyGEProof
     */
    alpha?: string;
    /**
     * 
     * @type {string}
     * @memberof IndyGEProof
     */
    mj?: string;
    /**
     * 
     * @type {IndyGEProofPred}
     * @memberof IndyGEProof
     */
    predicate?: IndyGEProofPred;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyGEProof
     */
    r?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyGEProof
     */
    t?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyGEProof
     */
    u?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface IndyGEProofPred
 */
export interface IndyGEProofPred {
    /**
     * Attribute name, indy-canonicalized
     * @type {string}
     * @memberof IndyGEProofPred
     */
    attr_name?: string;
    /**
     * Predicate type
     * @type {string}
     * @memberof IndyGEProofPred
     */
    p_type?: IndyGEProofPred.PTypeEnum;
    /**
     * Predicate threshold value
     * @type {number}
     * @memberof IndyGEProofPred
     */
    value?: number;
}

/**
 * @export
 * @namespace IndyGEProofPred
 */
export namespace IndyGEProofPred {
    /**
     * @export
     * @enum {string}
     */
    export enum PTypeEnum {
        LT = <any> 'LT',
        LE = <any> 'LE',
        GE = <any> 'GE',
        GT = <any> 'GT'
    }
}
/**
 * 
 * @export
 * @interface IndyKeyCorrectnessProof
 */
export interface IndyKeyCorrectnessProof {
    /**
     * c in key correctness proof
     * @type {string}
     * @memberof IndyKeyCorrectnessProof
     */
    c: string;
    /**
     * xr_cap in key correctness proof
     * @type {Array<Array<string>>}
     * @memberof IndyKeyCorrectnessProof
     */
    xr_cap: Array<Array<string>>;
    /**
     * xz_cap in key correctness proof
     * @type {string}
     * @memberof IndyKeyCorrectnessProof
     */
    xz_cap: string;
}
/**
 * 
 * @export
 * @interface IndyNonRevocProof
 */
export interface IndyNonRevocProof {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyNonRevocProof
     */
    c_list?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IndyNonRevocProof
     */
    x_list?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface IndyNonRevocationInterval
 */
export interface IndyNonRevocationInterval {
    /**
     * Earliest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyNonRevocationInterval
     */
    from?: number;
    /**
     * Latest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyNonRevocationInterval
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface IndyPresAttrSpec
 */
export interface IndyPresAttrSpec {
    /**
     * 
     * @type {string}
     * @memberof IndyPresAttrSpec
     */
    cred_def_id?: string;
    /**
     * MIME type (default null)
     * @type {string}
     * @memberof IndyPresAttrSpec
     */
    mime_type?: string;
    /**
     * Attribute name
     * @type {string}
     * @memberof IndyPresAttrSpec
     */
    name: string;
    /**
     * Credential referent
     * @type {string}
     * @memberof IndyPresAttrSpec
     */
    referent?: string;
    /**
     * Attribute value
     * @type {string}
     * @memberof IndyPresAttrSpec
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface IndyPresPredSpec
 */
export interface IndyPresPredSpec {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyPresPredSpec
     */
    cred_def_id?: string;
    /**
     * Attribute name
     * @type {string}
     * @memberof IndyPresPredSpec
     */
    name: string;
    /**
     * Predicate type ('<', '<=', '>=', or '>')
     * @type {string}
     * @memberof IndyPresPredSpec
     */
    predicate: IndyPresPredSpec.PredicateEnum;
    /**
     * Threshold value
     * @type {number}
     * @memberof IndyPresPredSpec
     */
    threshold: number;
}

/**
 * @export
 * @namespace IndyPresPredSpec
 */
export namespace IndyPresPredSpec {
    /**
     * @export
     * @enum {string}
     */
    export enum PredicateEnum {
        LessThan = <any> '<',
        LessThanOrEqualTo = <any> '<=',
        GreaterThanOrEqualTo = <any> '>=',
        GreaterThan = <any> '>'
    }
}
/**
 * 
 * @export
 * @interface IndyPresPreview
 */
export interface IndyPresPreview {
    /**
     * Message type identifier
     * @type {string}
     * @memberof IndyPresPreview
     */
    type?: string;
    /**
     * 
     * @type {Array<IndyPresAttrSpec>}
     * @memberof IndyPresPreview
     */
    attributes: Array<IndyPresAttrSpec>;
    /**
     * 
     * @type {Array<IndyPresPredSpec>}
     * @memberof IndyPresPreview
     */
    predicates: Array<IndyPresPredSpec>;
}
/**
 * 
 * @export
 * @interface IndyPresSpec
 */
export interface IndyPresSpec {
    /**
     * Nested object mapping proof request attribute referents to requested-attribute specifiers
     * @type {{ [key: string]: IndyRequestedCredsRequestedAttr; }}
     * @memberof IndyPresSpec
     */
    requested_attributes: { [key: string]: IndyRequestedCredsRequestedAttr; };
    /**
     * Nested object mapping proof request predicate referents to requested-predicate specifiers
     * @type {{ [key: string]: IndyRequestedCredsRequestedPred; }}
     * @memberof IndyPresSpec
     */
    requested_predicates: { [key: string]: IndyRequestedCredsRequestedPred; };
    /**
     * Self-attested attributes to build into proof
     * @type {{ [key: string]: string; }}
     * @memberof IndyPresSpec
     */
    self_attested_attributes: { [key: string]: string; };
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof IndyPresSpec
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface IndyPrimaryProof
 */
export interface IndyPrimaryProof {
    /**
     * Indy equality proof
     * @type {IndyEQProof}
     * @memberof IndyPrimaryProof
     */
    eq_proof?: IndyEQProof;
    /**
     * Indy GE proofs
     * @type {Array<IndyGEProof>}
     * @memberof IndyPrimaryProof
     */
    ge_proofs?: Array<IndyGEProof>;
}
/**
 * 
 * @export
 * @interface IndyProof
 */
export interface IndyProof {
    /**
     * Indy proof.identifiers content
     * @type {Array<IndyProofIdentifier>}
     * @memberof IndyProof
     */
    identifiers?: Array<IndyProofIdentifier>;
    /**
     * Indy proof.proof content
     * @type {IndyProofProof}
     * @memberof IndyProof
     */
    proof?: IndyProofProof;
    /**
     * Indy proof.requested_proof content
     * @type {IndyProofRequestedProof}
     * @memberof IndyProof
     */
    requested_proof?: IndyProofRequestedProof;
}
/**
 * 
 * @export
 * @interface IndyProofIdentifier
 */
export interface IndyProofIdentifier {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyProofIdentifier
     */
    cred_def_id?: string;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof IndyProofIdentifier
     */
    rev_reg_id?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof IndyProofIdentifier
     */
    schema_id?: string;
    /**
     * Timestamp epoch
     * @type {number}
     * @memberof IndyProofIdentifier
     */
    timestamp?: number;
}
/**
 * 
 * @export
 * @interface IndyProofProof
 */
export interface IndyProofProof {
    /**
     * Indy proof aggregated proof
     * @type {IndyProofProofAggregatedProof}
     * @memberof IndyProofProof
     */
    aggregated_proof?: IndyProofProofAggregatedProof;
    /**
     * Indy proof proofs
     * @type {Array<IndyProofProofProofsProof>}
     * @memberof IndyProofProof
     */
    proofs?: Array<IndyProofProofProofsProof>;
}
/**
 * 
 * @export
 * @interface IndyProofProofAggregatedProof
 */
export interface IndyProofProofAggregatedProof {
    /**
     * c_hash value
     * @type {string}
     * @memberof IndyProofProofAggregatedProof
     */
    c_hash?: string;
    /**
     * c_list value
     * @type {Array<Array<number>>}
     * @memberof IndyProofProofAggregatedProof
     */
    c_list?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface IndyProofProofProofsProof
 */
export interface IndyProofProofProofsProof {
    /**
     * Indy non-revocation proof
     * @type {IndyNonRevocProof}
     * @memberof IndyProofProofProofsProof
     */
    non_revoc_proof?: IndyNonRevocProof;
    /**
     * Indy primary proof
     * @type {IndyPrimaryProof}
     * @memberof IndyProofProofProofsProof
     */
    primary_proof?: IndyPrimaryProof;
}
/**
 * 
 * @export
 * @interface IndyProofReqAttrSpec
 */
export interface IndyProofReqAttrSpec {
    /**
     * Attribute name
     * @type {string}
     * @memberof IndyProofReqAttrSpec
     */
    name?: string;
    /**
     * Attribute name group
     * @type {Array<string>}
     * @memberof IndyProofReqAttrSpec
     */
    names?: Array<string>;
    /**
     * 
     * @type {IndyProofReqAttrSpecNonRevoked}
     * @memberof IndyProofReqAttrSpec
     */
    non_revoked?: IndyProofReqAttrSpecNonRevoked;
    /**
     * If present, credential must satisfy one of given restrictions: specify schema_id, schema_issuer_did, schema_name, schema_version, issuer_did, cred_def_id, and/or attr::<attribute-name>::value where <attribute-name> represents a credential attribute name
     * @type {Array<{ [key: string]: string; }>}
     * @memberof IndyProofReqAttrSpec
     */
    restrictions?: Array<{ [key: string]: string; }>;
}
/**
 * 
 * @export
 * @interface IndyProofReqAttrSpecNonRevoked
 */
export interface IndyProofReqAttrSpecNonRevoked {
    /**
     * Earliest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofReqAttrSpecNonRevoked
     */
    from?: number;
    /**
     * Latest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofReqAttrSpecNonRevoked
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface IndyProofReqPredSpec
 */
export interface IndyProofReqPredSpec {
    /**
     * Attribute name
     * @type {string}
     * @memberof IndyProofReqPredSpec
     */
    name: string;
    /**
     * 
     * @type {IndyProofReqPredSpecNonRevoked}
     * @memberof IndyProofReqPredSpec
     */
    non_revoked?: IndyProofReqPredSpecNonRevoked;
    /**
     * Predicate type ('<', '<=', '>=', or '>')
     * @type {string}
     * @memberof IndyProofReqPredSpec
     */
    p_type: IndyProofReqPredSpec.PTypeEnum;
    /**
     * Threshold value
     * @type {number}
     * @memberof IndyProofReqPredSpec
     */
    p_value: number;
    /**
     * If present, credential must satisfy one of given restrictions: specify schema_id, schema_issuer_did, schema_name, schema_version, issuer_did, cred_def_id, and/or attr::<attribute-name>::value where <attribute-name> represents a credential attribute name
     * @type {Array<{ [key: string]: string; }>}
     * @memberof IndyProofReqPredSpec
     */
    restrictions?: Array<{ [key: string]: string; }>;
}

/**
 * @export
 * @namespace IndyProofReqPredSpec
 */
export namespace IndyProofReqPredSpec {
    /**
     * @export
     * @enum {string}
     */
    export enum PTypeEnum {
        LessThan = <any> '<',
        LessThanOrEqualTo = <any> '<=',
        GreaterThanOrEqualTo = <any> '>=',
        GreaterThan = <any> '>'
    }
}
/**
 * 
 * @export
 * @interface IndyProofReqPredSpecNonRevoked
 */
export interface IndyProofReqPredSpecNonRevoked {
    /**
     * Earliest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofReqPredSpecNonRevoked
     */
    from?: number;
    /**
     * Latest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofReqPredSpecNonRevoked
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface IndyProofRequest
 */
export interface IndyProofRequest {
    /**
     * Proof request name
     * @type {string}
     * @memberof IndyProofRequest
     */
    name?: string;
    /**
     * 
     * @type {IndyProofRequestNonRevoked}
     * @memberof IndyProofRequest
     */
    non_revoked?: IndyProofRequestNonRevoked;
    /**
     * Nonce
     * @type {string}
     * @memberof IndyProofRequest
     */
    nonce?: string;
    /**
     * Requested attribute specifications of proof request
     * @type {{ [key: string]: IndyProofReqAttrSpec; }}
     * @memberof IndyProofRequest
     */
    requested_attributes: { [key: string]: IndyProofReqAttrSpec; };
    /**
     * Requested predicate specifications of proof request
     * @type {{ [key: string]: IndyProofReqPredSpec; }}
     * @memberof IndyProofRequest
     */
    requested_predicates: { [key: string]: IndyProofReqPredSpec; };
    /**
     * Proof request version
     * @type {string}
     * @memberof IndyProofRequest
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface IndyProofRequestNonRevoked
 */
export interface IndyProofRequestNonRevoked {
    /**
     * Earliest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofRequestNonRevoked
     */
    from?: number;
    /**
     * Latest time of interest in non-revocation interval
     * @type {number}
     * @memberof IndyProofRequestNonRevoked
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface IndyProofRequestedProof
 */
export interface IndyProofRequestedProof {
    /**
     * Proof requested proof predicates.
     * @type {{ [key: string]: IndyProofRequestedProofPredicate; }}
     * @memberof IndyProofRequestedProof
     */
    predicates?: { [key: string]: IndyProofRequestedProofPredicate; };
    /**
     * Proof requested proof revealed attribute groups
     * @type {{ [key: string]: IndyProofRequestedProofRevealedAttrGroup; }}
     * @memberof IndyProofRequestedProof
     */
    revealed_attr_groups?: { [key: string]: IndyProofRequestedProofRevealedAttrGroup; };
    /**
     * Proof requested proof revealed attributes
     * @type {{ [key: string]: IndyProofRequestedProofRevealedAttr; }}
     * @memberof IndyProofRequestedProof
     */
    revealed_attrs?: { [key: string]: IndyProofRequestedProofRevealedAttr; };
    /**
     * Proof requested proof self-attested attributes
     * @type {any}
     * @memberof IndyProofRequestedProof
     */
    self_attested_attrs?: any;
    /**
     * Unrevealed attributes
     * @type {any}
     * @memberof IndyProofRequestedProof
     */
    unrevealed_attrs?: any;
}
/**
 * 
 * @export
 * @interface IndyProofRequestedProofPredicate
 */
export interface IndyProofRequestedProofPredicate {
    /**
     * Sub-proof index
     * @type {number}
     * @memberof IndyProofRequestedProofPredicate
     */
    sub_proof_index?: number;
}
/**
 * 
 * @export
 * @interface IndyProofRequestedProofRevealedAttr
 */
export interface IndyProofRequestedProofRevealedAttr {
    /**
     * Encoded value
     * @type {string}
     * @memberof IndyProofRequestedProofRevealedAttr
     */
    encoded?: string;
    /**
     * Raw value
     * @type {string}
     * @memberof IndyProofRequestedProofRevealedAttr
     */
    raw?: string;
    /**
     * Sub-proof index
     * @type {number}
     * @memberof IndyProofRequestedProofRevealedAttr
     */
    sub_proof_index?: number;
}
/**
 * 
 * @export
 * @interface IndyProofRequestedProofRevealedAttrGroup
 */
export interface IndyProofRequestedProofRevealedAttrGroup {
    /**
     * Sub-proof index
     * @type {number}
     * @memberof IndyProofRequestedProofRevealedAttrGroup
     */
    sub_proof_index?: number;
    /**
     * Indy proof requested proof revealed attr groups group value
     * @type {{ [key: string]: RawEncoded; }}
     * @memberof IndyProofRequestedProofRevealedAttrGroup
     */
    values?: { [key: string]: RawEncoded; };
}
/**
 * 
 * @export
 * @interface IndyRequestedCredsRequestedAttr
 */
export interface IndyRequestedCredsRequestedAttr {
    /**
     * Wallet credential identifier (typically but not necessarily a UUID)
     * @type {string}
     * @memberof IndyRequestedCredsRequestedAttr
     */
    cred_id: string;
    /**
     * Whether to reveal attribute in proof (default true)
     * @type {boolean}
     * @memberof IndyRequestedCredsRequestedAttr
     */
    revealed?: boolean;
}
/**
 * 
 * @export
 * @interface IndyRequestedCredsRequestedPred
 */
export interface IndyRequestedCredsRequestedPred {
    /**
     * Wallet credential identifier (typically but not necessarily a UUID)
     * @type {string}
     * @memberof IndyRequestedCredsRequestedPred
     */
    cred_id: string;
    /**
     * Epoch timestamp of interest for non-revocation proof
     * @type {number}
     * @memberof IndyRequestedCredsRequestedPred
     */
    timestamp?: number;
}
/**
 * 
 * @export
 * @interface IndyRevRegDef
 */
export interface IndyRevRegDef {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IndyRevRegDef
     */
    credDefId?: string;
    /**
     * Indy revocation registry identifier
     * @type {string}
     * @memberof IndyRevRegDef
     */
    id?: string;
    /**
     * Revocation registry type (specify CL_ACCUM)
     * @type {string}
     * @memberof IndyRevRegDef
     */
    revocDefType?: IndyRevRegDef.RevocDefTypeEnum;
    /**
     * Revocation registry tag
     * @type {string}
     * @memberof IndyRevRegDef
     */
    tag?: string;
    /**
     * Revocation registry definition value
     * @type {IndyRevRegDefValue}
     * @memberof IndyRevRegDef
     */
    value?: IndyRevRegDefValue;
    /**
     * Version of revocation registry definition
     * @type {string}
     * @memberof IndyRevRegDef
     */
    ver?: string;
}

/**
 * @export
 * @namespace IndyRevRegDef
 */
export namespace IndyRevRegDef {
    /**
     * @export
     * @enum {string}
     */
    export enum RevocDefTypeEnum {
        CLACCUM = <any> 'CL_ACCUM'
    }
}
/**
 * 
 * @export
 * @interface IndyRevRegDefValue
 */
export interface IndyRevRegDefValue {
    /**
     * Issuance type
     * @type {string}
     * @memberof IndyRevRegDefValue
     */
    issuanceType?: IndyRevRegDefValue.IssuanceTypeEnum;
    /**
     * Maximum number of credentials; registry size
     * @type {number}
     * @memberof IndyRevRegDefValue
     */
    maxCredNum?: number;
    /**
     * Public keys
     * @type {IndyRevRegDefValuePublicKeys}
     * @memberof IndyRevRegDefValue
     */
    publicKeys?: IndyRevRegDefValuePublicKeys;
    /**
     * Tails hash value
     * @type {string}
     * @memberof IndyRevRegDefValue
     */
    tailsHash?: string;
    /**
     * Tails file location
     * @type {string}
     * @memberof IndyRevRegDefValue
     */
    tailsLocation?: string;
}

/**
 * @export
 * @namespace IndyRevRegDefValue
 */
export namespace IndyRevRegDefValue {
    /**
     * @export
     * @enum {string}
     */
    export enum IssuanceTypeEnum {
        ONDEMAND = <any> 'ISSUANCE_ON_DEMAND',
        BYDEFAULT = <any> 'ISSUANCE_BY_DEFAULT'
    }
}
/**
 * 
 * @export
 * @interface IndyRevRegDefValuePublicKeys
 */
export interface IndyRevRegDefValuePublicKeys {
    /**
     * 
     * @type {IndyRevRegDefValuePublicKeysAccumKey}
     * @memberof IndyRevRegDefValuePublicKeys
     */
    accumKey?: IndyRevRegDefValuePublicKeysAccumKey;
}
/**
 * 
 * @export
 * @interface IndyRevRegDefValuePublicKeysAccumKey
 */
export interface IndyRevRegDefValuePublicKeysAccumKey {
    /**
     * Value for z
     * @type {string}
     * @memberof IndyRevRegDefValuePublicKeysAccumKey
     */
    z?: string;
}
/**
 * 
 * @export
 * @interface IndyRevRegEntry
 */
export interface IndyRevRegEntry {
    /**
     * Revocation registry entry value
     * @type {IndyRevRegEntryValue}
     * @memberof IndyRevRegEntry
     */
    value?: IndyRevRegEntryValue;
    /**
     * Version of revocation registry entry
     * @type {string}
     * @memberof IndyRevRegEntry
     */
    ver?: string;
}
/**
 * 
 * @export
 * @interface IndyRevRegEntryValue
 */
export interface IndyRevRegEntryValue {
    /**
     * Accumulator value
     * @type {string}
     * @memberof IndyRevRegEntryValue
     */
    accum?: string;
    /**
     * Previous accumulator value
     * @type {string}
     * @memberof IndyRevRegEntryValue
     */
    prevAccum?: string;
    /**
     * Revoked credential revocation identifiers
     * @type {Array<number>}
     * @memberof IndyRevRegEntryValue
     */
    revoked?: Array<number>;
}
/**
 * 
 * @export
 * @interface InputDescriptors
 */
export interface InputDescriptors {
    /**
     * 
     * @type {Constraints}
     * @memberof InputDescriptors
     */
    constraints?: Constraints;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputDescriptors
     */
    group?: Array<string>;
    /**
     * ID
     * @type {string}
     * @memberof InputDescriptors
     */
    id?: string;
    /**
     * Metadata dictionary
     * @type {any}
     * @memberof InputDescriptors
     */
    metadata?: any;
    /**
     * Name
     * @type {string}
     * @memberof InputDescriptors
     */
    name?: string;
    /**
     * Purpose
     * @type {string}
     * @memberof InputDescriptors
     */
    purpose?: string;
    /**
     * Accepts a list of schema or a dict containing filters like oneof_filter.
     * @type {SchemasInputDescriptorFilter}
     * @memberof InputDescriptors
     */
    schema?: SchemasInputDescriptorFilter;
}
/**
 * 
 * @export
 * @interface IntroModuleResponse
 */
export interface IntroModuleResponse {
}
/**
 * 
 * @export
 * @interface InvitationCreateRequest
 */
export interface InvitationCreateRequest {
    /**
     * Alias for connection
     * @type {string}
     * @memberof InvitationCreateRequest
     */
    alias?: string;
    /**
     * Optional invitation attachments
     * @type {Array<AttachmentDef>}
     * @memberof InvitationCreateRequest
     */
    attachments?: Array<AttachmentDef>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationCreateRequest
     */
    handshake_protocols?: Array<string>;
    /**
     * Identifier for active mediation record to be used
     * @type {string}
     * @memberof InvitationCreateRequest
     */
    mediation_id?: string;
    /**
     * Optional metadata to attach to the connection created with the invitation
     * @type {any}
     * @memberof InvitationCreateRequest
     */
    metadata?: any;
    /**
     * Label for connection invitation
     * @type {string}
     * @memberof InvitationCreateRequest
     */
    my_label?: string;
    /**
     * Whether to use public DID in invitation
     * @type {boolean}
     * @memberof InvitationCreateRequest
     */
    use_public_did?: boolean;
}
/**
 * 
 * @export
 * @interface InvitationMessage
 */
export interface InvitationMessage {
    /**
     * Message identifier
     * @type {string}
     * @memberof InvitationMessage
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof InvitationMessage
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationMessage
     */
    handshake_protocols?: Array<string>;
    /**
     * Optional label
     * @type {string}
     * @memberof InvitationMessage
     */
    label?: string;
    /**
     * Optional request attachment
     * @type {Array<AttachDecorator>}
     * @memberof InvitationMessage
     */
    requestsattach?: Array<AttachDecorator>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InvitationMessage
     */
    services?: Array<any>;
}
/**
 * 
 * @export
 * @interface InvitationRecord
 */
export interface InvitationRecord {
    /**
     * Time of record creation
     * @type {string}
     * @memberof InvitationRecord
     */
    created_at?: string;
    /**
     * Invitation message identifier
     * @type {string}
     * @memberof InvitationRecord
     */
    invi_msg_id?: string;
    /**
     * Out of band invitation message
     * @type {InvitationMessage}
     * @memberof InvitationRecord
     */
    invitation?: InvitationMessage;
    /**
     * Invitation record identifier
     * @type {string}
     * @memberof InvitationRecord
     */
    invitation_id?: string;
    /**
     * Invitation message URL
     * @type {string}
     * @memberof InvitationRecord
     */
    invitation_url?: string;
    /**
     * Out of band record identifier
     * @type {string}
     * @memberof InvitationRecord
     */
    oob_id?: string;
    /**
     * Out of band message exchange state
     * @type {string}
     * @memberof InvitationRecord
     */
    state?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof InvitationRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof InvitationRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface InvitationResult
 */
export interface InvitationResult {
    /**
     * Connection identifier
     * @type {string}
     * @memberof InvitationResult
     */
    connection_id?: string;
    /**
     * 
     * @type {ConnectionInvitation}
     * @memberof InvitationResult
     */
    invitation?: ConnectionInvitation;
    /**
     * Invitation URL
     * @type {string}
     * @memberof InvitationResult
     */
    invitation_url?: string;
}
/**
 * 
 * @export
 * @interface IssueCredentialModuleResponse
 */
export interface IssueCredentialModuleResponse {
}
/**
 * 
 * @export
 * @interface IssuerCredRevRecord
 */
export interface IssuerCredRevRecord {
    /**
     * Time of record creation
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    created_at?: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    cred_def_id?: string;
    /**
     * Credential exchange record identifier at credential issue
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    cred_ex_id?: string;
    /**
     * Credential exchange version
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    cred_ex_version?: string;
    /**
     * Credential revocation identifier
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    cred_rev_id?: string;
    /**
     * Issuer credential revocation record identifier
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    record_id?: string;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    rev_reg_id?: string;
    /**
     * Issue credential revocation record state
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    state?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof IssuerCredRevRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface IssuerRevRegRecord
 */
export interface IssuerRevRegRecord {
    /**
     * Time of record creation
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    created_at?: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    cred_def_id?: string;
    /**
     * Error message
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    error_msg?: string;
    /**
     * Issuer DID
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    issuer_did?: string;
    /**
     * Maximum number of credentials for revocation registry
     * @type {number}
     * @memberof IssuerRevRegRecord
     */
    max_cred_num?: number;
    /**
     * Credential revocation identifier for credential revoked and pending publication to ledger
     * @type {Array<string>}
     * @memberof IssuerRevRegRecord
     */
    pending_pub?: Array<string>;
    /**
     * Issuer revocation registry record identifier
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    record_id?: string;
    /**
     * Revocation registry type (specify CL_ACCUM)
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    revoc_def_type?: IssuerRevRegRecord.RevocDefTypeEnum;
    /**
     * Revocation registry definition
     * @type {IndyRevRegDef}
     * @memberof IssuerRevRegRecord
     */
    revoc_reg_def?: IndyRevRegDef;
    /**
     * Revocation registry entry
     * @type {IndyRevRegEntry}
     * @memberof IssuerRevRegRecord
     */
    revoc_reg_entry?: IndyRevRegEntry;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    revoc_reg_id?: string;
    /**
     * Issue revocation registry record state
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    state?: string;
    /**
     * Tag within issuer revocation registry identifier
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    tag?: string;
    /**
     * Tails hash
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    tails_hash?: string;
    /**
     * Local path to tails file
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    tails_local_path?: string;
    /**
     * Public URI for tails file
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    tails_public_uri?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof IssuerRevRegRecord
     */
    updated_at?: string;
}

/**
 * @export
 * @namespace IssuerRevRegRecord
 */
export namespace IssuerRevRegRecord {
    /**
     * @export
     * @enum {string}
     */
    export enum RevocDefTypeEnum {
        CLACCUM = <any> 'CL_ACCUM'
    }
}
/**
 * 
 * @export
 * @interface Keylist
 */
export interface Keylist {
    /**
     * List of keylist records
     * @type {Array<RouteRecord>}
     * @memberof Keylist
     */
    results?: Array<RouteRecord>;
}
/**
 * 
 * @export
 * @interface KeylistQuery
 */
export interface KeylistQuery {
    /**
     * Message identifier
     * @type {string}
     * @memberof KeylistQuery
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof KeylistQuery
     */
    type?: string;
    /**
     * Query dictionary object
     * @type {any}
     * @memberof KeylistQuery
     */
    filter?: any;
    /**
     * Pagination info
     * @type {KeylistQueryPaginate}
     * @memberof KeylistQuery
     */
    paginate?: KeylistQueryPaginate;
}
/**
 * 
 * @export
 * @interface KeylistQueryFilterRequest
 */
export interface KeylistQueryFilterRequest {
    /**
     * Filter for keylist query
     * @type {any}
     * @memberof KeylistQueryFilterRequest
     */
    filter?: any;
}
/**
 * 
 * @export
 * @interface KeylistQueryPaginate
 */
export interface KeylistQueryPaginate {
    /**
     * Limit for keylist query
     * @type {number}
     * @memberof KeylistQueryPaginate
     */
    limit?: number;
    /**
     * Offset value for query
     * @type {number}
     * @memberof KeylistQueryPaginate
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface KeylistUpdate
 */
export interface KeylistUpdate {
    /**
     * Message identifier
     * @type {string}
     * @memberof KeylistUpdate
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof KeylistUpdate
     */
    type?: string;
    /**
     * List of update rules
     * @type {Array<KeylistUpdateRule>}
     * @memberof KeylistUpdate
     */
    updates?: Array<KeylistUpdateRule>;
}
/**
 * 
 * @export
 * @interface KeylistUpdateRequest
 */
export interface KeylistUpdateRequest {
    /**
     * 
     * @type {Array<KeylistUpdateRule>}
     * @memberof KeylistUpdateRequest
     */
    updates?: Array<KeylistUpdateRule>;
}
/**
 * 
 * @export
 * @interface KeylistUpdateRule
 */
export interface KeylistUpdateRule {
    /**
     * Action for specific key
     * @type {string}
     * @memberof KeylistUpdateRule
     */
    action: KeylistUpdateRule.ActionEnum;
    /**
     * Key to remove or add
     * @type {string}
     * @memberof KeylistUpdateRule
     */
    recipient_key: string;
}

/**
 * @export
 * @namespace KeylistUpdateRule
 */
export namespace KeylistUpdateRule {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Add = <any> 'add',
        Remove = <any> 'remove'
    }
}
/**
 * 
 * @export
 * @interface LDProofVCDetail
 */
export interface LDProofVCDetail {
    /**
     * Detail of the JSON-LD Credential to be issued
     * @type {Credential}
     * @memberof LDProofVCDetail
     */
    credential: Credential;
    /**
     * Options for specifying how the linked data proof is created.
     * @type {LDProofVCDetailOptions}
     * @memberof LDProofVCDetail
     */
    options: LDProofVCDetailOptions;
}
/**
 * 
 * @export
 * @interface LDProofVCDetailOptions
 */
export interface LDProofVCDetailOptions {
    /**
     * A challenge to include in the proof. SHOULD be provided by the requesting party of the credential (=holder)
     * @type {string}
     * @memberof LDProofVCDetailOptions
     */
    challenge?: string;
    /**
     * The date and time of the proof (with a maximum accuracy in seconds). Defaults to current system time
     * @type {string}
     * @memberof LDProofVCDetailOptions
     */
    created?: string;
    /**
     * The credential status mechanism to use for the credential. Omitting the property indicates the issued credential will not include a credential status
     * @type {CredentialStatusOptions}
     * @memberof LDProofVCDetailOptions
     */
    credentialStatus?: CredentialStatusOptions;
    /**
     * The intended domain of validity for the proof
     * @type {string}
     * @memberof LDProofVCDetailOptions
     */
    domain?: string;
    /**
     * The proof purpose used for the proof. Should match proof purposes registered in the Linked Data Proofs Specification
     * @type {string}
     * @memberof LDProofVCDetailOptions
     */
    proofPurpose?: string;
    /**
     * The proof type used for the proof. Should match suites registered in the Linked Data Cryptographic Suite Registry
     * @type {string}
     * @memberof LDProofVCDetailOptions
     */
    proofType: string;
}
/**
 * 
 * @export
 * @interface LedgerConfigInstance
 */
export interface LedgerConfigInstance {
    /**
     * genesis_file
     * @type {string}
     * @memberof LedgerConfigInstance
     */
    genesis_file?: string;
    /**
     * genesis_transactions
     * @type {string}
     * @memberof LedgerConfigInstance
     */
    genesis_transactions?: string;
    /**
     * genesis_url
     * @type {string}
     * @memberof LedgerConfigInstance
     */
    genesis_url?: string;
    /**
     * ledger_id
     * @type {string}
     * @memberof LedgerConfigInstance
     */
    id?: string;
    /**
     * is_production
     * @type {boolean}
     * @memberof LedgerConfigInstance
     */
    is_production?: boolean;
}
/**
 * 
 * @export
 * @interface LedgerConfigList
 */
export interface LedgerConfigList {
    /**
     * 
     * @type {Array<LedgerConfigInstance>}
     * @memberof LedgerConfigList
     */
    ledger_config_list: Array<LedgerConfigInstance>;
}
/**
 * 
 * @export
 * @interface LedgerModulesResult
 */
export interface LedgerModulesResult {
}
/**
 * 
 * @export
 * @interface LinkedDataProof
 */
export interface LinkedDataProof {
    /**
     * Associates a challenge with a proof, for use with a proofPurpose such as authentication
     * @type {string}
     * @memberof LinkedDataProof
     */
    challenge?: string;
    /**
     * The string value of an ISO8601 combined date and time string generated by the Signature Algorithm
     * @type {string}
     * @memberof LinkedDataProof
     */
    created: string;
    /**
     * A string value specifying the restricted domain of the signature.
     * @type {string}
     * @memberof LinkedDataProof
     */
    domain?: string;
    /**
     * Associates a Detached Json Web Signature with a proof
     * @type {string}
     * @memberof LinkedDataProof
     */
    jws?: string;
    /**
     * The nonce
     * @type {string}
     * @memberof LinkedDataProof
     */
    nonce?: string;
    /**
     * Proof purpose
     * @type {string}
     * @memberof LinkedDataProof
     */
    proofPurpose: string;
    /**
     * The proof value of a proof
     * @type {string}
     * @memberof LinkedDataProof
     */
    proofValue?: string;
    /**
     * Identifies the digital signature suite that was used to create the signature
     * @type {string}
     * @memberof LinkedDataProof
     */
    type: string;
    /**
     * Information used for proof verification
     * @type {string}
     * @memberof LinkedDataProof
     */
    verificationMethod: string;
}
/**
 * 
 * @export
 * @interface MediationCreateRequest
 */
export interface MediationCreateRequest {
    /**
     * List of mediator rules for recipient
     * @type {Array<string>}
     * @memberof MediationCreateRequest
     */
    mediator_terms?: Array<string>;
    /**
     * List of recipient rules for mediation
     * @type {Array<string>}
     * @memberof MediationCreateRequest
     */
    recipient_terms?: Array<string>;
}
/**
 * 
 * @export
 * @interface MediationDeny
 */
export interface MediationDeny {
    /**
     * Message identifier
     * @type {string}
     * @memberof MediationDeny
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof MediationDeny
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationDeny
     */
    mediator_terms?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationDeny
     */
    recipient_terms?: Array<string>;
}
/**
 * 
 * @export
 * @interface MediationGrant
 */
export interface MediationGrant {
    /**
     * Message identifier
     * @type {string}
     * @memberof MediationGrant
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof MediationGrant
     */
    type?: string;
    /**
     * endpoint on which messages destined for the recipient are received.
     * @type {string}
     * @memberof MediationGrant
     */
    endpoint?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationGrant
     */
    routing_keys?: Array<string>;
}
/**
 * 
 * @export
 * @interface MediationIdMatchInfo
 */
export interface MediationIdMatchInfo {
    /**
     * Mediation record identifier
     * @type {string}
     * @memberof MediationIdMatchInfo
     */
    mediation_id?: string;
}
/**
 * 
 * @export
 * @interface MediationList
 */
export interface MediationList {
    /**
     * List of mediation records
     * @type {Array<MediationRecord>}
     * @memberof MediationList
     */
    results?: Array<MediationRecord>;
}
/**
 * 
 * @export
 * @interface MediationRecord
 */
export interface MediationRecord {
    /**
     * 
     * @type {string}
     * @memberof MediationRecord
     */
    connection_id: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof MediationRecord
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MediationRecord
     */
    endpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof MediationRecord
     */
    mediation_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationRecord
     */
    mediator_terms?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationRecord
     */
    recipient_terms?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MediationRecord
     */
    role: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediationRecord
     */
    routing_keys?: Array<string>;
    /**
     * Current record state
     * @type {string}
     * @memberof MediationRecord
     */
    state?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof MediationRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     * Message identifier
     * @type {string}
     * @memberof Menu
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof Menu
     */
    type?: string;
    /**
     * Introductory text for the menu
     * @type {string}
     * @memberof Menu
     */
    description?: string;
    /**
     * An optional error message to display in menu header
     * @type {string}
     * @memberof Menu
     */
    errormsg?: string;
    /**
     * List of menu options
     * @type {Array<MenuOption>}
     * @memberof Menu
     */
    options: Array<MenuOption>;
    /**
     * Menu title
     * @type {string}
     * @memberof Menu
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface MenuForm
 */
export interface MenuForm {
    /**
     * Additional descriptive text for menu form
     * @type {string}
     * @memberof MenuForm
     */
    description?: string;
    /**
     * List of form parameters
     * @type {Array<MenuFormParam>}
     * @memberof MenuForm
     */
    params?: Array<MenuFormParam>;
    /**
     * Alternative label for form submit button
     * @type {string}
     * @memberof MenuForm
     */
    submit_label?: string;
    /**
     * Menu form title
     * @type {string}
     * @memberof MenuForm
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface MenuFormParam
 */
export interface MenuFormParam {
    /**
     * Default parameter value
     * @type {string}
     * @memberof MenuFormParam
     */
    _default?: string;
    /**
     * Additional descriptive text for menu form parameter
     * @type {string}
     * @memberof MenuFormParam
     */
    description?: string;
    /**
     * Menu parameter name
     * @type {string}
     * @memberof MenuFormParam
     */
    name: string;
    /**
     * Whether parameter is required
     * @type {boolean}
     * @memberof MenuFormParam
     */
    required?: boolean;
    /**
     * Menu parameter title
     * @type {string}
     * @memberof MenuFormParam
     */
    title: string;
    /**
     * Menu form parameter input type
     * @type {string}
     * @memberof MenuFormParam
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MenuJson
 */
export interface MenuJson {
    /**
     * Introductory text for the menu
     * @type {string}
     * @memberof MenuJson
     */
    description?: string;
    /**
     * Optional error message to display in menu header
     * @type {string}
     * @memberof MenuJson
     */
    errormsg?: string;
    /**
     * List of menu options
     * @type {Array<MenuOption>}
     * @memberof MenuJson
     */
    options: Array<MenuOption>;
    /**
     * Menu title
     * @type {string}
     * @memberof MenuJson
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface MenuOption
 */
export interface MenuOption {
    /**
     * Additional descriptive text for menu option
     * @type {string}
     * @memberof MenuOption
     */
    description?: string;
    /**
     * Whether to show option as disabled
     * @type {boolean}
     * @memberof MenuOption
     */
    disabled?: boolean;
    /**
     * 
     * @type {MenuForm}
     * @memberof MenuOption
     */
    form?: MenuForm;
    /**
     * Menu option name (unique identifier)
     * @type {string}
     * @memberof MenuOption
     */
    name: string;
    /**
     * Menu option title
     * @type {string}
     * @memberof MenuOption
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ModelDate
 */
export interface ModelDate {
    /**
     * Expiry Date
     * @type {Date}
     * @memberof ModelDate
     */
    expires_time: Date;
}
/**
 * 
 * @export
 * @interface OobRecord
 */
export interface OobRecord {
    /**
     * Connection record identifier
     * @type {string}
     * @memberof OobRecord
     */
    attach_thread_id?: string;
    /**
     * Connection record identifier
     * @type {string}
     * @memberof OobRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof OobRecord
     */
    created_at?: string;
    /**
     * Invitation message identifier
     * @type {string}
     * @memberof OobRecord
     */
    invi_msg_id: string;
    /**
     * Out of band invitation message
     * @type {InvitationMessage}
     * @memberof OobRecord
     */
    invitation: InvitationMessage;
    /**
     * Oob record identifier
     * @type {string}
     * @memberof OobRecord
     */
    oob_id: string;
    /**
     * Recipient key used for oob invitation
     * @type {string}
     * @memberof OobRecord
     */
    our_recipient_key?: string;
    /**
     * OOB Role
     * @type {string}
     * @memberof OobRecord
     */
    role?: string;
    /**
     * Out of band message exchange state
     * @type {string}
     * @memberof OobRecord
     */
    state: string;
    /**
     * 
     * @type {ServiceDecorator}
     * @memberof OobRecord
     */
    their_service?: ServiceDecorator;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof OobRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof OobRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface PerformRequest
 */
export interface PerformRequest {
    /**
     * Menu option name
     * @type {string}
     * @memberof PerformRequest
     */
    name?: string;
    /**
     * Input parameter values
     * @type {{ [key: string]: string; }}
     * @memberof PerformRequest
     */
    params?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PingRequest
 */
export interface PingRequest {
    /**
     * Comment for the ping message
     * @type {string}
     * @memberof PingRequest
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface PingRequestResponse
 */
export interface PingRequestResponse {
    /**
     * Thread ID of the ping message
     * @type {string}
     * @memberof PingRequestResponse
     */
    thread_id?: string;
}
/**
 * 
 * @export
 * @interface PresentationDefinition
 */
export interface PresentationDefinition {
    /**
     * 
     * @type {ClaimFormat}
     * @memberof PresentationDefinition
     */
    format?: ClaimFormat;
    /**
     * Unique Resource Identifier
     * @type {string}
     * @memberof PresentationDefinition
     */
    id?: string;
    /**
     * 
     * @type {Array<InputDescriptors>}
     * @memberof PresentationDefinition
     */
    input_descriptors?: Array<InputDescriptors>;
    /**
     * Human-friendly name that describes what the presentation definition pertains to
     * @type {string}
     * @memberof PresentationDefinition
     */
    name?: string;
    /**
     * Describes the purpose for which the Presentation Definition's inputs are being requested
     * @type {string}
     * @memberof PresentationDefinition
     */
    purpose?: string;
    /**
     * 
     * @type {Array<SubmissionRequirements>}
     * @memberof PresentationDefinition
     */
    submission_requirements?: Array<SubmissionRequirements>;
}
/**
 * 
 * @export
 * @interface PresentationProposal
 */
export interface PresentationProposal {
    /**
     * Message identifier
     * @type {string}
     * @memberof PresentationProposal
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof PresentationProposal
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof PresentationProposal
     */
    comment?: string;
    /**
     * 
     * @type {IndyPresPreview}
     * @memberof PresentationProposal
     */
    presentation_proposal: IndyPresPreview;
}
/**
 * 
 * @export
 * @interface PresentationRequest
 */
export interface PresentationRequest {
    /**
     * Message identifier
     * @type {string}
     * @memberof PresentationRequest
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof PresentationRequest
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof PresentationRequest
     */
    comment?: string;
    /**
     * 
     * @type {Array<AttachDecorator>}
     * @memberof PresentationRequest
     */
    request_presentationsattach: Array<AttachDecorator>;
}
/**
 * 
 * @export
 * @interface ProtocolDescriptor
 */
export interface ProtocolDescriptor {
    /**
     * 
     * @type {string}
     * @memberof ProtocolDescriptor
     */
    pid: string;
    /**
     * List of roles
     * @type {Array<string>}
     * @memberof ProtocolDescriptor
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface PublishRevocations
 */
export interface PublishRevocations {
    /**
     * Credential revocation ids by revocation registry id
     * @type {{ [key: string]: Array<string>; }}
     * @memberof PublishRevocations
     */
    rrid2crid?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface Queries
 */
export interface Queries {
    /**
     * Message identifier
     * @type {string}
     * @memberof Queries
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof Queries
     */
    type?: string;
    /**
     * 
     * @type {Array<QueryItem>}
     * @memberof Queries
     */
    queries?: Array<QueryItem>;
}
/**
 * 
 * @export
 * @interface Query
 */
export interface Query {
    /**
     * Message identifier
     * @type {string}
     * @memberof Query
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof Query
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Query
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Query
     */
    query: string;
}
/**
 * 
 * @export
 * @interface QueryItem
 */
export interface QueryItem {
    /**
     * feature type
     * @type {string}
     * @memberof QueryItem
     */
    feature_type: QueryItem.FeatureTypeEnum;
    /**
     * match
     * @type {string}
     * @memberof QueryItem
     */
    match: string;
}

/**
 * @export
 * @namespace QueryItem
 */
export namespace QueryItem {
    /**
     * @export
     * @enum {string}
     */
    export enum FeatureTypeEnum {
        Protocol = <any> 'protocol',
        GoalCode = <any> 'goal-code'
    }
}
/**
 * 
 * @export
 * @interface RawEncoded
 */
export interface RawEncoded {
    /**
     * Encoded value
     * @type {string}
     * @memberof RawEncoded
     */
    encoded?: string;
    /**
     * Raw value
     * @type {string}
     * @memberof RawEncoded
     */
    raw?: string;
}
/**
 * 
 * @export
 * @interface ReceiveInvitationRequest
 */
export interface ReceiveInvitationRequest {
    /**
     * Message identifier
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    type?: string;
    /**
     * DID for connection invitation
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    did?: string;
    /**
     * Optional image URL for connection invitation
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    imageUrl?: string;
    /**
     * Optional label for connection invitation
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    label?: string;
    /**
     * List of recipient keys
     * @type {Array<string>}
     * @memberof ReceiveInvitationRequest
     */
    recipientKeys?: Array<string>;
    /**
     * List of routing keys
     * @type {Array<string>}
     * @memberof ReceiveInvitationRequest
     */
    routingKeys?: Array<string>;
    /**
     * Service endpoint at which to reach this agent
     * @type {string}
     * @memberof ReceiveInvitationRequest
     */
    serviceEndpoint?: string;
}
/**
 * 
 * @export
 * @interface ResolutionResult
 */
export interface ResolutionResult {
    /**
     * DID Document
     * @type {any}
     * @memberof ResolutionResult
     */
    did_doc: any;
    /**
     * Resolution metadata
     * @type {any}
     * @memberof ResolutionResult
     */
    metadata: any;
}
/**
 * 
 * @export
 * @interface RevRegCreateRequest
 */
export interface RevRegCreateRequest {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof RevRegCreateRequest
     */
    credential_definition_id?: string;
    /**
     * Revocation registry size
     * @type {number}
     * @memberof RevRegCreateRequest
     */
    max_cred_num?: number;
}
/**
 * 
 * @export
 * @interface RevRegIssuedResult
 */
export interface RevRegIssuedResult {
    /**
     * Number of credentials issued against revocation registry
     * @type {number}
     * @memberof RevRegIssuedResult
     */
    result?: number;
}
/**
 * 
 * @export
 * @interface RevRegResult
 */
export interface RevRegResult {
    /**
     * 
     * @type {IssuerRevRegRecord}
     * @memberof RevRegResult
     */
    result?: IssuerRevRegRecord;
}
/**
 * 
 * @export
 * @interface RevRegUpdateTailsFileUri
 */
export interface RevRegUpdateTailsFileUri {
    /**
     * Public URI to the tails file
     * @type {string}
     * @memberof RevRegUpdateTailsFileUri
     */
    tails_public_uri: string;
}
/**
 * 
 * @export
 * @interface RevRegWalletUpdatedResult
 */
export interface RevRegWalletUpdatedResult {
    /**
     * Calculated accumulator for phantom revocations
     * @type {any}
     * @memberof RevRegWalletUpdatedResult
     */
    accum_calculated?: any;
    /**
     * Applied ledger transaction to fix revocations
     * @type {any}
     * @memberof RevRegWalletUpdatedResult
     */
    accum_fixed?: any;
    /**
     * Indy revocation registry delta
     * @type {any}
     * @memberof RevRegWalletUpdatedResult
     */
    rev_reg_delta?: any;
}
/**
 * 
 * @export
 * @interface RevRegsCreated
 */
export interface RevRegsCreated {
    /**
     * 
     * @type {Array<string>}
     * @memberof RevRegsCreated
     */
    rev_reg_ids?: Array<string>;
}
/**
 * 
 * @export
 * @interface RevocationModuleResponse
 */
export interface RevocationModuleResponse {
}
/**
 * 
 * @export
 * @interface RevokeRequest
 */
export interface RevokeRequest {
    /**
     * Optional comment to include in revocation notification
     * @type {string}
     * @memberof RevokeRequest
     */
    comment?: string;
    /**
     * Connection ID to which the revocation notification will be sent; required if notify is true
     * @type {string}
     * @memberof RevokeRequest
     */
    connection_id?: string;
    /**
     * Credential exchange identifier
     * @type {string}
     * @memberof RevokeRequest
     */
    cred_ex_id?: string;
    /**
     * Credential revocation identifier
     * @type {string}
     * @memberof RevokeRequest
     */
    cred_rev_id?: string;
    /**
     * Send a notification to the credential recipient
     * @type {boolean}
     * @memberof RevokeRequest
     */
    notify?: boolean;
    /**
     * Specify which version of the revocation notification should be sent
     * @type {string}
     * @memberof RevokeRequest
     */
    notify_version?: RevokeRequest.NotifyVersionEnum;
    /**
     * (True) publish revocation to ledger immediately, or (default, False) mark it pending
     * @type {boolean}
     * @memberof RevokeRequest
     */
    publish?: boolean;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof RevokeRequest
     */
    rev_reg_id?: string;
    /**
     * Thread ID of the credential exchange message thread resulting in the credential now being revoked; required if notify is true
     * @type {string}
     * @memberof RevokeRequest
     */
    thread_id?: string;
}

/**
 * @export
 * @namespace RevokeRequest
 */
export namespace RevokeRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum NotifyVersionEnum {
        V10 = <any> 'v1_0',
        V20 = <any> 'v2_0'
    }
}
/**
 * 
 * @export
 * @interface RouteRecord
 */
export interface RouteRecord {
    /**
     * 
     * @type {string}
     * @memberof RouteRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof RouteRecord
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof RouteRecord
     */
    recipient_key: string;
    /**
     * 
     * @type {string}
     * @memberof RouteRecord
     */
    record_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RouteRecord
     */
    role?: string;
    /**
     * Current record state
     * @type {string}
     * @memberof RouteRecord
     */
    state?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof RouteRecord
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof RouteRecord
     */
    wallet_id?: string;
}
/**
 * 
 * @export
 * @interface Schema
 */
export interface Schema {
    /**
     * Schema attribute names
     * @type {Array<string>}
     * @memberof Schema
     */
    attrNames?: Array<string>;
    /**
     * Schema identifier
     * @type {string}
     * @memberof Schema
     */
    id?: string;
    /**
     * Schema name
     * @type {string}
     * @memberof Schema
     */
    name?: string;
    /**
     * Schema sequence number
     * @type {number}
     * @memberof Schema
     */
    seqNo?: number;
    /**
     * Node protocol version
     * @type {string}
     * @memberof Schema
     */
    ver?: string;
    /**
     * Schema version
     * @type {string}
     * @memberof Schema
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface SchemaGetResult
 */
export interface SchemaGetResult {
    /**
     * 
     * @type {Schema}
     * @memberof SchemaGetResult
     */
    schema?: Schema;
}
/**
 * 
 * @export
 * @interface SchemaInputDescriptor
 */
export interface SchemaInputDescriptor {
    /**
     * Required
     * @type {boolean}
     * @memberof SchemaInputDescriptor
     */
    required?: boolean;
    /**
     * URI
     * @type {string}
     * @memberof SchemaInputDescriptor
     */
    uri?: string;
}
/**
 * 
 * @export
 * @interface SchemaSendRequest
 */
export interface SchemaSendRequest {
    /**
     * List of schema attributes
     * @type {Array<string>}
     * @memberof SchemaSendRequest
     */
    attributes: Array<string>;
    /**
     * Schema name
     * @type {string}
     * @memberof SchemaSendRequest
     */
    schema_name: string;
    /**
     * Schema version
     * @type {string}
     * @memberof SchemaSendRequest
     */
    schema_version: string;
}
/**
 * 
 * @export
 * @interface SchemaSendResult
 */
export interface SchemaSendResult {
    /**
     * Schema definition
     * @type {Schema}
     * @memberof SchemaSendResult
     */
    schema?: Schema;
    /**
     * Schema identifier
     * @type {string}
     * @memberof SchemaSendResult
     */
    schema_id: string;
}
/**
 * 
 * @export
 * @interface SchemasCreatedResult
 */
export interface SchemasCreatedResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof SchemasCreatedResult
     */
    schema_ids?: Array<string>;
}
/**
 * 
 * @export
 * @interface SchemasInputDescriptorFilter
 */
export interface SchemasInputDescriptorFilter {
    /**
     * oneOf
     * @type {boolean}
     * @memberof SchemasInputDescriptorFilter
     */
    oneof_filter?: boolean;
    /**
     * 
     * @type {Array<Array<SchemaInputDescriptor>>}
     * @memberof SchemasInputDescriptorFilter
     */
    uri_groups?: Array<Array<SchemaInputDescriptor>>;
}
/**
 * 
 * @export
 * @interface SendMenu
 */
export interface SendMenu {
    /**
     * Menu to send to connection
     * @type {MenuJson}
     * @memberof SendMenu
     */
    menu: MenuJson;
}
/**
 * 
 * @export
 * @interface SendMessage
 */
export interface SendMessage {
    /**
     * Message content
     * @type {string}
     * @memberof SendMessage
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface ServiceDecorator
 */
export interface ServiceDecorator {
    /**
     * List of recipient keys
     * @type {Array<string>}
     * @memberof ServiceDecorator
     */
    recipientKeys: Array<string>;
    /**
     * List of routing keys
     * @type {Array<string>}
     * @memberof ServiceDecorator
     */
    routingKeys?: Array<string>;
    /**
     * Service endpoint at which to reach this agent
     * @type {string}
     * @memberof ServiceDecorator
     */
    serviceEndpoint: string;
}
/**
 * 
 * @export
 * @interface SignRequest
 */
export interface SignRequest {
    /**
     * 
     * @type {Doc}
     * @memberof SignRequest
     */
    doc: Doc;
    /**
     * Verkey to use for signing
     * @type {string}
     * @memberof SignRequest
     */
    verkey: string;
}
/**
 * 
 * @export
 * @interface SignResponse
 */
export interface SignResponse {
    /**
     * Error text
     * @type {string}
     * @memberof SignResponse
     */
    error?: string;
    /**
     * Signed document
     * @type {any}
     * @memberof SignResponse
     */
    signed_doc?: any;
}
/**
 * 
 * @export
 * @interface SignatureOptions
 */
export interface SignatureOptions {
    /**
     * 
     * @type {string}
     * @memberof SignatureOptions
     */
    challenge?: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureOptions
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureOptions
     */
    proofPurpose: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureOptions
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureOptions
     */
    verificationMethod: string;
}
/**
 * 
 * @export
 * @interface SignedDoc
 */
export interface SignedDoc {
    /**
     * Linked data proof
     * @type {SignatureOptions}
     * @memberof SignedDoc
     */
    proof: SignatureOptions;
}
/**
 * 
 * @export
 * @interface SubmissionRequirements
 */
export interface SubmissionRequirements {
    /**
     * Count Value
     * @type {number}
     * @memberof SubmissionRequirements
     */
    count?: number;
    /**
     * From
     * @type {string}
     * @memberof SubmissionRequirements
     */
    from?: string;
    /**
     * 
     * @type {Array<SubmissionRequirements>}
     * @memberof SubmissionRequirements
     */
    from_nested?: Array<SubmissionRequirements>;
    /**
     * Max Value
     * @type {number}
     * @memberof SubmissionRequirements
     */
    max?: number;
    /**
     * Min Value
     * @type {number}
     * @memberof SubmissionRequirements
     */
    min?: number;
    /**
     * Name
     * @type {string}
     * @memberof SubmissionRequirements
     */
    name?: string;
    /**
     * Purpose
     * @type {string}
     * @memberof SubmissionRequirements
     */
    purpose?: string;
    /**
     * Selection
     * @type {string}
     * @memberof SubmissionRequirements
     */
    rule?: SubmissionRequirements.RuleEnum;
}

/**
 * @export
 * @namespace SubmissionRequirements
 */
export namespace SubmissionRequirements {
    /**
     * @export
     * @enum {string}
     */
    export enum RuleEnum {
        All = <any> 'all',
        Pick = <any> 'pick'
    }
}
/**
 * 
 * @export
 * @interface TAAAccept
 */
export interface TAAAccept {
    /**
     * 
     * @type {string}
     * @memberof TAAAccept
     */
    mechanism?: string;
    /**
     * 
     * @type {string}
     * @memberof TAAAccept
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof TAAAccept
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface TAAAcceptance
 */
export interface TAAAcceptance {
    /**
     * 
     * @type {string}
     * @memberof TAAAcceptance
     */
    mechanism?: string;
    /**
     * 
     * @type {number}
     * @memberof TAAAcceptance
     */
    time?: number;
}
/**
 * 
 * @export
 * @interface TAAInfo
 */
export interface TAAInfo {
    /**
     * 
     * @type {AMLRecord}
     * @memberof TAAInfo
     */
    aml_record?: AMLRecord;
    /**
     * 
     * @type {TAAAcceptance}
     * @memberof TAAInfo
     */
    taa_accepted?: TAAAcceptance;
    /**
     * 
     * @type {TAARecord}
     * @memberof TAAInfo
     */
    taa_record?: TAARecord;
    /**
     * 
     * @type {boolean}
     * @memberof TAAInfo
     */
    taa_required?: boolean;
}
/**
 * 
 * @export
 * @interface TAARecord
 */
export interface TAARecord {
    /**
     * 
     * @type {string}
     * @memberof TAARecord
     */
    digest?: string;
    /**
     * 
     * @type {string}
     * @memberof TAARecord
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof TAARecord
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface TAAResult
 */
export interface TAAResult {
    /**
     * 
     * @type {TAAInfo}
     * @memberof TAAResult
     */
    result?: TAAInfo;
}
/**
 * 
 * @export
 * @interface TransactionJobs
 */
export interface TransactionJobs {
    /**
     * My transaction related job
     * @type {string}
     * @memberof TransactionJobs
     */
    transaction_my_job?: TransactionJobs.TransactionMyJobEnum;
    /**
     * Their transaction related job
     * @type {string}
     * @memberof TransactionJobs
     */
    transaction_their_job?: TransactionJobs.TransactionTheirJobEnum;
}

/**
 * @export
 * @namespace TransactionJobs
 */
export namespace TransactionJobs {
    /**
     * @export
     * @enum {string}
     */
    export enum TransactionMyJobEnum {
        TRANSACTIONAUTHOR = <any> 'TRANSACTION_AUTHOR',
        TRANSACTIONENDORSER = <any> 'TRANSACTION_ENDORSER',
        Reset = <any> 'reset'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TransactionTheirJobEnum {
        TRANSACTIONAUTHOR = <any> 'TRANSACTION_AUTHOR',
        TRANSACTIONENDORSER = <any> 'TRANSACTION_ENDORSER',
        Reset = <any> 'reset'
    }
}
/**
 * 
 * @export
 * @interface TransactionList
 */
export interface TransactionList {
    /**
     * List of transaction records
     * @type {Array<TransactionRecord>}
     * @memberof TransactionList
     */
    results?: Array<TransactionRecord>;
}
/**
 * 
 * @export
 * @interface TransactionRecord
 */
export interface TransactionRecord {
    /**
     * Transaction type
     * @type {string}
     * @memberof TransactionRecord
     */
    _type?: string;
    /**
     * The connection identifier for thie particular transaction record
     * @type {string}
     * @memberof TransactionRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof TransactionRecord
     */
    created_at?: string;
    /**
     * If True, Endorser will write the transaction after endorsing it
     * @type {boolean}
     * @memberof TransactionRecord
     */
    endorser_write_txn?: boolean;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof TransactionRecord
     */
    formats?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Array<any>}
     * @memberof TransactionRecord
     */
    messages_attach?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof TransactionRecord
     */
    meta_data?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof TransactionRecord
     */
    signature_request?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof TransactionRecord
     */
    signature_response?: Array<any>;
    /**
     * Current record state
     * @type {string}
     * @memberof TransactionRecord
     */
    state?: string;
    /**
     * Thread Identifier
     * @type {string}
     * @memberof TransactionRecord
     */
    thread_id?: string;
    /**
     * 
     * @type {any}
     * @memberof TransactionRecord
     */
    timing?: any;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof TransactionRecord
     */
    trace?: boolean;
    /**
     * Transaction identifier
     * @type {string}
     * @memberof TransactionRecord
     */
    transaction_id?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof TransactionRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface TxnOrCredentialDefinitionSendResult
 */
export interface TxnOrCredentialDefinitionSendResult {
    /**
     * 
     * @type {CredentialDefinitionSendResult}
     * @memberof TxnOrCredentialDefinitionSendResult
     */
    sent?: CredentialDefinitionSendResult;
    /**
     * Credential definition transaction to endorse
     * @type {TransactionRecord}
     * @memberof TxnOrCredentialDefinitionSendResult
     */
    txn?: TransactionRecord;
}
/**
 * 
 * @export
 * @interface TxnOrPublishRevocationsResult
 */
export interface TxnOrPublishRevocationsResult {
    /**
     * 
     * @type {PublishRevocations}
     * @memberof TxnOrPublishRevocationsResult
     */
    sent?: PublishRevocations;
    /**
     * Revocation registry revocations transaction to endorse
     * @type {TransactionRecord}
     * @memberof TxnOrPublishRevocationsResult
     */
    txn?: TransactionRecord;
}
/**
 * 
 * @export
 * @interface TxnOrRegisterLedgerNymResponse
 */
export interface TxnOrRegisterLedgerNymResponse {
    /**
     * Success of nym registration operation
     * @type {boolean}
     * @memberof TxnOrRegisterLedgerNymResponse
     */
    success?: boolean;
    /**
     * DID transaction to endorse
     * @type {TransactionRecord}
     * @memberof TxnOrRegisterLedgerNymResponse
     */
    txn?: TransactionRecord;
}
/**
 * 
 * @export
 * @interface TxnOrRevRegResult
 */
export interface TxnOrRevRegResult {
    /**
     * 
     * @type {RevRegResult}
     * @memberof TxnOrRevRegResult
     */
    sent?: RevRegResult;
    /**
     * Revocation registry definition transaction to endorse
     * @type {TransactionRecord}
     * @memberof TxnOrRevRegResult
     */
    txn?: TransactionRecord;
}
/**
 * 
 * @export
 * @interface TxnOrSchemaSendResult
 */
export interface TxnOrSchemaSendResult {
    /**
     * Content sent
     * @type {SchemaSendResult}
     * @memberof TxnOrSchemaSendResult
     */
    sent?: SchemaSendResult;
    /**
     * Schema transaction to endorse
     * @type {TransactionRecord}
     * @memberof TxnOrSchemaSendResult
     */
    txn?: TransactionRecord;
}
/**
 * 
 * @export
 * @interface V10CredentialBoundOfferRequest
 */
export interface V10CredentialBoundOfferRequest {
    /**
     * Optional counter-proposal
     * @type {CredentialProposal}
     * @memberof V10CredentialBoundOfferRequest
     */
    counter_proposal?: CredentialProposal;
}
/**
 * 
 * @export
 * @interface V10CredentialConnFreeOfferRequest
 */
export interface V10CredentialConnFreeOfferRequest {
    /**
     * Whether to respond automatically to credential requests, creating and issuing requested credentials
     * @type {boolean}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    auto_issue?: boolean;
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    comment?: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    cred_def_id: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    credential_preview: CredentialPreview;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialConnFreeOfferRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10CredentialCreate
 */
export interface V10CredentialCreate {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V10CredentialCreate
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialCreate
     */
    comment?: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialCreate
     */
    cred_def_id?: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof V10CredentialCreate
     */
    credential_proposal: CredentialPreview;
    /**
     * Credential issuer DID
     * @type {string}
     * @memberof V10CredentialCreate
     */
    issuer_did?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof V10CredentialCreate
     */
    schema_id?: string;
    /**
     * Schema issuer DID
     * @type {string}
     * @memberof V10CredentialCreate
     */
    schema_issuer_did?: string;
    /**
     * Schema name
     * @type {string}
     * @memberof V10CredentialCreate
     */
    schema_name?: string;
    /**
     * Schema version
     * @type {string}
     * @memberof V10CredentialCreate
     */
    schema_version?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialCreate
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10CredentialExchange
 */
export interface V10CredentialExchange {
    /**
     * Issuer choice to issue to request in this credential exchange
     * @type {boolean}
     * @memberof V10CredentialExchange
     */
    auto_issue?: boolean;
    /**
     * Holder choice to accept offer in this credential exchange
     * @type {boolean}
     * @memberof V10CredentialExchange
     */
    auto_offer?: boolean;
    /**
     * Issuer choice to remove this credential exchange record when complete
     * @type {boolean}
     * @memberof V10CredentialExchange
     */
    auto_remove?: boolean;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V10CredentialExchange
     */
    created_at?: string;
    /**
     * Credential as stored
     * @type {IndyCredInfo}
     * @memberof V10CredentialExchange
     */
    credential?: IndyCredInfo;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    credential_definition_id?: string;
    /**
     * Credential exchange identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    credential_exchange_id?: string;
    /**
     * Credential identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    credential_id?: string;
    /**
     * (Indy) credential offer
     * @type {IndyCredAbstract}
     * @memberof V10CredentialExchange
     */
    credential_offer?: IndyCredAbstract;
    /**
     * Credential offer message
     * @type {CredentialOffer}
     * @memberof V10CredentialExchange
     */
    credential_offer_dict?: CredentialOffer;
    /**
     * Credential proposal message
     * @type {CredentialProposal}
     * @memberof V10CredentialExchange
     */
    credential_proposal_dict?: CredentialProposal;
    /**
     * (Indy) credential request
     * @type {IndyCredRequest}
     * @memberof V10CredentialExchange
     */
    credential_request?: IndyCredRequest;
    /**
     * (Indy) credential request metadata
     * @type {any}
     * @memberof V10CredentialExchange
     */
    credential_request_metadata?: any;
    /**
     * Error message
     * @type {string}
     * @memberof V10CredentialExchange
     */
    error_msg?: string;
    /**
     * Issue-credential exchange initiator: self or external
     * @type {string}
     * @memberof V10CredentialExchange
     */
    initiator?: V10CredentialExchange.InitiatorEnum;
    /**
     * Parent thread identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    parent_thread_id?: string;
    /**
     * Credential as received, prior to storage in holder wallet
     * @type {IndyCredential}
     * @memberof V10CredentialExchange
     */
    raw_credential?: IndyCredential;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    revoc_reg_id?: string;
    /**
     * Credential identifier within revocation registry
     * @type {string}
     * @memberof V10CredentialExchange
     */
    revocation_id?: string;
    /**
     * Issue-credential exchange role: holder or issuer
     * @type {string}
     * @memberof V10CredentialExchange
     */
    role?: V10CredentialExchange.RoleEnum;
    /**
     * Schema identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    schema_id?: string;
    /**
     * Issue-credential exchange state
     * @type {string}
     * @memberof V10CredentialExchange
     */
    state?: string;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V10CredentialExchange
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialExchange
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V10CredentialExchange
     */
    updated_at?: string;
}

/**
 * @export
 * @namespace V10CredentialExchange
 */
export namespace V10CredentialExchange {
    /**
     * @export
     * @enum {string}
     */
    export enum InitiatorEnum {
        Self = <any> 'self',
        External = <any> 'external'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Holder = <any> 'holder',
        Issuer = <any> 'issuer'
    }
}
/**
 * 
 * @export
 * @interface V10CredentialExchangeListResult
 */
export interface V10CredentialExchangeListResult {
    /**
     * Aries#0036 v1.0 credential exchange records
     * @type {Array<V10CredentialExchange>}
     * @memberof V10CredentialExchangeListResult
     */
    results?: Array<V10CredentialExchange>;
}
/**
 * 
 * @export
 * @interface V10CredentialFreeOfferRequest
 */
export interface V10CredentialFreeOfferRequest {
    /**
     * Whether to respond automatically to credential requests, creating and issuing requested credentials
     * @type {boolean}
     * @memberof V10CredentialFreeOfferRequest
     */
    auto_issue?: boolean;
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V10CredentialFreeOfferRequest
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialFreeOfferRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10CredentialFreeOfferRequest
     */
    connection_id: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialFreeOfferRequest
     */
    cred_def_id: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof V10CredentialFreeOfferRequest
     */
    credential_preview: CredentialPreview;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialFreeOfferRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10CredentialIssueRequest
 */
export interface V10CredentialIssueRequest {
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialIssueRequest
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface V10CredentialProblemReportRequest
 */
export interface V10CredentialProblemReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V10CredentialProblemReportRequest
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V10CredentialProposalRequestMand
 */
export interface V10CredentialProposalRequestMand {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V10CredentialProposalRequestMand
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    connection_id: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    cred_def_id?: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof V10CredentialProposalRequestMand
     */
    credential_proposal: CredentialPreview;
    /**
     * Credential issuer DID
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    issuer_did?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    schema_id?: string;
    /**
     * Schema issuer DID
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    schema_issuer_did?: string;
    /**
     * Schema name
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    schema_name?: string;
    /**
     * Schema version
     * @type {string}
     * @memberof V10CredentialProposalRequestMand
     */
    schema_version?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialProposalRequestMand
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10CredentialProposalRequestOpt
 */
export interface V10CredentialProposalRequestOpt {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V10CredentialProposalRequestOpt
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    connection_id: string;
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    cred_def_id?: string;
    /**
     * 
     * @type {CredentialPreview}
     * @memberof V10CredentialProposalRequestOpt
     */
    credential_proposal?: CredentialPreview;
    /**
     * Credential issuer DID
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    issuer_did?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    schema_id?: string;
    /**
     * Schema issuer DID
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    schema_issuer_did?: string;
    /**
     * Schema name
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    schema_name?: string;
    /**
     * Schema version
     * @type {string}
     * @memberof V10CredentialProposalRequestOpt
     */
    schema_version?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10CredentialProposalRequestOpt
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10CredentialStoreRequest
 */
export interface V10CredentialStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof V10CredentialStoreRequest
     */
    credential_id?: string;
}
/**
 * 
 * @export
 * @interface V10DiscoveryExchangeListResult
 */
export interface V10DiscoveryExchangeListResult {
    /**
     * 
     * @type {Array<V10DiscoveryRecord>}
     * @memberof V10DiscoveryExchangeListResult
     */
    results?: Array<V10DiscoveryRecord>;
}
/**
 * 
 * @export
 * @interface V10DiscoveryRecord
 */
export interface V10DiscoveryRecord {
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    created_at?: string;
    /**
     * Disclose message
     * @type {Disclose}
     * @memberof V10DiscoveryRecord
     */
    disclose?: Disclose;
    /**
     * Credential exchange identifier
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    discovery_exchange_id?: string;
    /**
     * Query message
     * @type {Query}
     * @memberof V10DiscoveryRecord
     */
    query_msg?: Query;
    /**
     * Current record state
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    state?: string;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10DiscoveryRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V10DiscoveryRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface V10PresentProofModuleResponse
 */
export interface V10PresentProofModuleResponse {
}
/**
 * 
 * @export
 * @interface V10PresentationCreateRequestRequest
 */
export interface V10PresentationCreateRequestRequest {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V10PresentationCreateRequestRequest
     */
    auto_verify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V10PresentationCreateRequestRequest
     */
    comment?: string;
    /**
     * 
     * @type {IndyProofRequest}
     * @memberof V10PresentationCreateRequestRequest
     */
    proof_request: IndyProofRequest;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V10PresentationCreateRequestRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10PresentationExchange
 */
export interface V10PresentationExchange {
    /**
     * Prover choice to auto-present proof as verifier requests
     * @type {boolean}
     * @memberof V10PresentationExchange
     */
    auto_present?: boolean;
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V10PresentationExchange
     */
    auto_verify?: boolean;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10PresentationExchange
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V10PresentationExchange
     */
    created_at?: string;
    /**
     * Error message
     * @type {string}
     * @memberof V10PresentationExchange
     */
    error_msg?: string;
    /**
     * Present-proof exchange initiator: self or external
     * @type {string}
     * @memberof V10PresentationExchange
     */
    initiator?: V10PresentationExchange.InitiatorEnum;
    /**
     * (Indy) presentation (also known as proof)
     * @type {IndyProof}
     * @memberof V10PresentationExchange
     */
    presentation?: IndyProof;
    /**
     * Presentation exchange identifier
     * @type {string}
     * @memberof V10PresentationExchange
     */
    presentation_exchange_id?: string;
    /**
     * Presentation proposal message
     * @type {PresentationProposal}
     * @memberof V10PresentationExchange
     */
    presentation_proposal_dict?: PresentationProposal;
    /**
     * (Indy) presentation request (also known as proof request)
     * @type {IndyProofRequest}
     * @memberof V10PresentationExchange
     */
    presentation_request?: IndyProofRequest;
    /**
     * Presentation request message
     * @type {PresentationRequest}
     * @memberof V10PresentationExchange
     */
    presentation_request_dict?: PresentationRequest;
    /**
     * Present-proof exchange role: prover or verifier
     * @type {string}
     * @memberof V10PresentationExchange
     */
    role?: V10PresentationExchange.RoleEnum;
    /**
     * Present-proof exchange state
     * @type {string}
     * @memberof V10PresentationExchange
     */
    state?: string;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V10PresentationExchange
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V10PresentationExchange
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V10PresentationExchange
     */
    updated_at?: string;
    /**
     * Whether presentation is verified: true or false
     * @type {string}
     * @memberof V10PresentationExchange
     */
    verified?: V10PresentationExchange.VerifiedEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof V10PresentationExchange
     */
    verified_msgs?: Array<string>;
}

/**
 * @export
 * @namespace V10PresentationExchange
 */
export namespace V10PresentationExchange {
    /**
     * @export
     * @enum {string}
     */
    export enum InitiatorEnum {
        Self = <any> 'self',
        External = <any> 'external'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Prover = <any> 'prover',
        Verifier = <any> 'verifier'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VerifiedEnum {
        True = <any> 'true',
        False = <any> 'false'
    }
}
/**
 * 
 * @export
 * @interface V10PresentationExchangeList
 */
export interface V10PresentationExchangeList {
    /**
     * Aries RFC 37 v1.0 presentation exchange records
     * @type {Array<V10PresentationExchange>}
     * @memberof V10PresentationExchangeList
     */
    results?: Array<V10PresentationExchange>;
}
/**
 * 
 * @export
 * @interface V10PresentationProblemReportRequest
 */
export interface V10PresentationProblemReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V10PresentationProblemReportRequest
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V10PresentationProposalRequest
 */
export interface V10PresentationProposalRequest {
    /**
     * Whether to respond automatically to presentation requests, building and presenting requested proof
     * @type {boolean}
     * @memberof V10PresentationProposalRequest
     */
    auto_present?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V10PresentationProposalRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10PresentationProposalRequest
     */
    connection_id: string;
    /**
     * 
     * @type {IndyPresPreview}
     * @memberof V10PresentationProposalRequest
     */
    presentation_proposal: IndyPresPreview;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V10PresentationProposalRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10PresentationSendRequestRequest
 */
export interface V10PresentationSendRequestRequest {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V10PresentationSendRequestRequest
     */
    auto_verify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V10PresentationSendRequestRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V10PresentationSendRequestRequest
     */
    connection_id: string;
    /**
     * 
     * @type {IndyProofRequest}
     * @memberof V10PresentationSendRequestRequest
     */
    proof_request: IndyProofRequest;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V10PresentationSendRequestRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V10PresentationSendRequestToProposal
 */
export interface V10PresentationSendRequestToProposal {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V10PresentationSendRequestToProposal
     */
    auto_verify?: boolean;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V10PresentationSendRequestToProposal
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20CredAttrSpec
 */
export interface V20CredAttrSpec {
    /**
     * MIME type: omit for (null) default
     * @type {string}
     * @memberof V20CredAttrSpec
     */
    mime_type?: string;
    /**
     * Attribute name
     * @type {string}
     * @memberof V20CredAttrSpec
     */
    name: string;
    /**
     * Attribute value: base64-encode if MIME type is present
     * @type {string}
     * @memberof V20CredAttrSpec
     */
    value: string;
}
/**
 * 
 * @export
 * @interface V20CredBoundOfferRequest
 */
export interface V20CredBoundOfferRequest {
    /**
     * Optional content for counter-proposal
     * @type {V20CredPreview}
     * @memberof V20CredBoundOfferRequest
     */
    counter_preview?: V20CredPreview;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilter}
     * @memberof V20CredBoundOfferRequest
     */
    filter?: V20CredFilter;
}
/**
 * 
 * @export
 * @interface V20CredExFree
 */
export interface V20CredExFree {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V20CredExFree
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredExFree
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20CredExFree
     */
    connection_id: string;
    /**
     * 
     * @type {V20CredPreview}
     * @memberof V20CredExFree
     */
    credential_preview?: V20CredPreview;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilter}
     * @memberof V20CredExFree
     */
    filter: V20CredFilter;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20CredExFree
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20CredExRecord
 */
export interface V20CredExRecord {
    /**
     * Issuer choice to issue to request in this credential exchange
     * @type {boolean}
     * @memberof V20CredExRecord
     */
    auto_issue?: boolean;
    /**
     * Holder choice to accept offer in this credential exchange
     * @type {boolean}
     * @memberof V20CredExRecord
     */
    auto_offer?: boolean;
    /**
     * Issuer choice to remove this credential exchange record when complete
     * @type {boolean}
     * @memberof V20CredExRecord
     */
    auto_remove?: boolean;
    /**
     * Attachment content by format for proposal, offer, request, and issue
     * @type {V20CredExRecordByFormat}
     * @memberof V20CredExRecord
     */
    by_format?: V20CredExRecordByFormat;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20CredExRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V20CredExRecord
     */
    created_at?: string;
    /**
     * Credential exchange identifier
     * @type {string}
     * @memberof V20CredExRecord
     */
    cred_ex_id?: string;
    /**
     * Serialized credential issue message
     * @type {V20CredIssue}
     * @memberof V20CredExRecord
     */
    cred_issue?: V20CredIssue;
    /**
     * Credential offer message
     * @type {V20CredOffer}
     * @memberof V20CredExRecord
     */
    cred_offer?: V20CredOffer;
    /**
     * Credential preview from credential proposal
     * @type {V20CredPreview}
     * @memberof V20CredExRecord
     */
    cred_preview?: V20CredPreview;
    /**
     * Credential proposal message
     * @type {V20CredProposal}
     * @memberof V20CredExRecord
     */
    cred_proposal?: V20CredProposal;
    /**
     * Serialized credential request message
     * @type {V20CredRequest}
     * @memberof V20CredExRecord
     */
    cred_request?: V20CredRequest;
    /**
     * Error message
     * @type {string}
     * @memberof V20CredExRecord
     */
    error_msg?: string;
    /**
     * Issue-credential exchange initiator: self or external
     * @type {string}
     * @memberof V20CredExRecord
     */
    initiator?: V20CredExRecord.InitiatorEnum;
    /**
     * Parent thread identifier
     * @type {string}
     * @memberof V20CredExRecord
     */
    parent_thread_id?: string;
    /**
     * Issue-credential exchange role: holder or issuer
     * @type {string}
     * @memberof V20CredExRecord
     */
    role?: V20CredExRecord.RoleEnum;
    /**
     * Issue-credential exchange state
     * @type {string}
     * @memberof V20CredExRecord
     */
    state?: V20CredExRecord.StateEnum;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V20CredExRecord
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20CredExRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V20CredExRecord
     */
    updated_at?: string;
}

/**
 * @export
 * @namespace V20CredExRecord
 */
export namespace V20CredExRecord {
    /**
     * @export
     * @enum {string}
     */
    export enum InitiatorEnum {
        Self = <any> 'self',
        External = <any> 'external'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Issuer = <any> 'issuer',
        Holder = <any> 'holder'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        ProposalSent = <any> 'proposal-sent',
        ProposalReceived = <any> 'proposal-received',
        OfferSent = <any> 'offer-sent',
        OfferReceived = <any> 'offer-received',
        RequestSent = <any> 'request-sent',
        RequestReceived = <any> 'request-received',
        CredentialIssued = <any> 'credential-issued',
        CredentialReceived = <any> 'credential-received',
        Done = <any> 'done',
        CredentialRevoked = <any> 'credential-revoked',
        Abandoned = <any> 'abandoned'
    }
}
/**
 * 
 * @export
 * @interface V20CredExRecordByFormat
 */
export interface V20CredExRecordByFormat {
    /**
     * 
     * @type {any}
     * @memberof V20CredExRecordByFormat
     */
    cred_issue?: any;
    /**
     * 
     * @type {any}
     * @memberof V20CredExRecordByFormat
     */
    cred_offer?: any;
    /**
     * 
     * @type {any}
     * @memberof V20CredExRecordByFormat
     */
    cred_proposal?: any;
    /**
     * 
     * @type {any}
     * @memberof V20CredExRecordByFormat
     */
    cred_request?: any;
}
/**
 * 
 * @export
 * @interface V20CredExRecordDetail
 */
export interface V20CredExRecordDetail {
    /**
     * Credential exchange record
     * @type {V20CredExRecord}
     * @memberof V20CredExRecordDetail
     */
    cred_ex_record?: V20CredExRecord;
    /**
     * 
     * @type {V20CredExRecordIndy}
     * @memberof V20CredExRecordDetail
     */
    indy?: V20CredExRecordIndy;
    /**
     * 
     * @type {V20CredExRecordLDProof}
     * @memberof V20CredExRecordDetail
     */
    ld_proof?: V20CredExRecordLDProof;
}
/**
 * 
 * @export
 * @interface V20CredExRecordIndy
 */
export interface V20CredExRecordIndy {
    /**
     * Time of record creation
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    created_at?: string;
    /**
     * Corresponding v2.0 credential exchange record identifier
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    cred_ex_id?: string;
    /**
     * Record identifier
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    cred_ex_indy_id?: string;
    /**
     * Credential identifier stored in wallet
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    cred_id_stored?: string;
    /**
     * Credential request metadata for indy holder
     * @type {any}
     * @memberof V20CredExRecordIndy
     */
    cred_request_metadata?: any;
    /**
     * Credential revocation identifier within revocation registry
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    cred_rev_id?: string;
    /**
     * Revocation registry identifier
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    rev_reg_id?: string;
    /**
     * Current record state
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    state?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V20CredExRecordIndy
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface V20CredExRecordLDProof
 */
export interface V20CredExRecordLDProof {
    /**
     * Time of record creation
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    created_at?: string;
    /**
     * Corresponding v2.0 credential exchange record identifier
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    cred_ex_id?: string;
    /**
     * Record identifier
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    cred_ex_ld_proof_id?: string;
    /**
     * Credential identifier stored in wallet
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    cred_id_stored?: string;
    /**
     * Current record state
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    state?: string;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V20CredExRecordLDProof
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface V20CredExRecordListResult
 */
export interface V20CredExRecordListResult {
    /**
     * Credential exchange records and corresponding detail records
     * @type {Array<V20CredExRecordDetail>}
     * @memberof V20CredExRecordListResult
     */
    results?: Array<V20CredExRecordDetail>;
}
/**
 * 
 * @export
 * @interface V20CredFilter
 */
export interface V20CredFilter {
    /**
     * Credential filter for indy
     * @type {V20CredFilterIndy}
     * @memberof V20CredFilter
     */
    indy?: V20CredFilterIndy;
    /**
     * Credential filter for linked data proof
     * @type {LDProofVCDetail}
     * @memberof V20CredFilter
     */
    ld_proof?: LDProofVCDetail;
}
/**
 * 
 * @export
 * @interface V20CredFilterIndy
 */
export interface V20CredFilterIndy {
    /**
     * Credential definition identifier
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    cred_def_id?: string;
    /**
     * Credential issuer DID
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    issuer_did?: string;
    /**
     * Schema identifier
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    schema_id?: string;
    /**
     * Schema issuer DID
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    schema_issuer_did?: string;
    /**
     * Schema name
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    schema_name?: string;
    /**
     * Schema version
     * @type {string}
     * @memberof V20CredFilterIndy
     */
    schema_version?: string;
}
/**
 * 
 * @export
 * @interface V20CredFilterLDProof
 */
export interface V20CredFilterLDProof {
    /**
     * Credential filter for linked data proof
     * @type {LDProofVCDetail}
     * @memberof V20CredFilterLDProof
     */
    ld_proof: LDProofVCDetail;
}
/**
 * 
 * @export
 * @interface V20CredFormat
 */
export interface V20CredFormat {
    /**
     * Attachment identifier
     * @type {string}
     * @memberof V20CredFormat
     */
    attach_id: string;
    /**
     * Attachment format specifier
     * @type {string}
     * @memberof V20CredFormat
     */
    format: string;
}
/**
 * 
 * @export
 * @interface V20CredIssue
 */
export interface V20CredIssue {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20CredIssue
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20CredIssue
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredIssue
     */
    comment?: string;
    /**
     * Credential attachments
     * @type {Array<AttachDecorator>}
     * @memberof V20CredIssue
     */
    credentialsattach: Array<AttachDecorator>;
    /**
     * Acceptable attachment formats
     * @type {Array<V20CredFormat>}
     * @memberof V20CredIssue
     */
    formats: Array<V20CredFormat>;
    /**
     * Issuer-unique identifier to coordinate credential replacement
     * @type {string}
     * @memberof V20CredIssue
     */
    replacement_id?: string;
}
/**
 * 
 * @export
 * @interface V20CredIssueProblemReportRequest
 */
export interface V20CredIssueProblemReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V20CredIssueProblemReportRequest
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V20CredIssueRequest
 */
export interface V20CredIssueRequest {
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredIssueRequest
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface V20CredOffer
 */
export interface V20CredOffer {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20CredOffer
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20CredOffer
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredOffer
     */
    comment?: string;
    /**
     * 
     * @type {V20CredPreview}
     * @memberof V20CredOffer
     */
    credential_preview?: V20CredPreview;
    /**
     * Acceptable credential formats
     * @type {Array<V20CredFormat>}
     * @memberof V20CredOffer
     */
    formats: Array<V20CredFormat>;
    /**
     * Offer attachments
     * @type {Array<AttachDecorator>}
     * @memberof V20CredOffer
     */
    offersattach: Array<AttachDecorator>;
    /**
     * Issuer-unique identifier to coordinate credential replacement
     * @type {string}
     * @memberof V20CredOffer
     */
    replacement_id?: string;
}
/**
 * 
 * @export
 * @interface V20CredOfferConnFreeRequest
 */
export interface V20CredOfferConnFreeRequest {
    /**
     * Whether to respond automatically to credential requests, creating and issuing requested credentials
     * @type {boolean}
     * @memberof V20CredOfferConnFreeRequest
     */
    auto_issue?: boolean;
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V20CredOfferConnFreeRequest
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredOfferConnFreeRequest
     */
    comment?: string;
    /**
     * 
     * @type {V20CredPreview}
     * @memberof V20CredOfferConnFreeRequest
     */
    credential_preview?: V20CredPreview;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilter}
     * @memberof V20CredOfferConnFreeRequest
     */
    filter: V20CredFilter;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20CredOfferConnFreeRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20CredOfferRequest
 */
export interface V20CredOfferRequest {
    /**
     * Whether to respond automatically to credential requests, creating and issuing requested credentials
     * @type {boolean}
     * @memberof V20CredOfferRequest
     */
    auto_issue?: boolean;
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V20CredOfferRequest
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredOfferRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20CredOfferRequest
     */
    connection_id: string;
    /**
     * 
     * @type {V20CredPreview}
     * @memberof V20CredOfferRequest
     */
    credential_preview?: V20CredPreview;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilter}
     * @memberof V20CredOfferRequest
     */
    filter: V20CredFilter;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20CredOfferRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20CredPreview
 */
export interface V20CredPreview {
    /**
     * Message type identifier
     * @type {string}
     * @memberof V20CredPreview
     */
    type?: string;
    /**
     * 
     * @type {Array<V20CredAttrSpec>}
     * @memberof V20CredPreview
     */
    attributes: Array<V20CredAttrSpec>;
}
/**
 * 
 * @export
 * @interface V20CredProposal
 */
export interface V20CredProposal {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20CredProposal
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20CredProposal
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredProposal
     */
    comment?: string;
    /**
     * Credential preview
     * @type {V20CredPreview}
     * @memberof V20CredProposal
     */
    credential_preview?: V20CredPreview;
    /**
     * Credential filter per acceptable format on corresponding identifier
     * @type {Array<AttachDecorator>}
     * @memberof V20CredProposal
     */
    filtersattach: Array<AttachDecorator>;
    /**
     * Attachment formats
     * @type {Array<V20CredFormat>}
     * @memberof V20CredProposal
     */
    formats: Array<V20CredFormat>;
}
/**
 * 
 * @export
 * @interface V20CredRequest
 */
export interface V20CredRequest {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20CredRequest
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20CredRequest
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredRequest
     */
    comment?: string;
    /**
     * Acceptable attachment formats
     * @type {Array<V20CredFormat>}
     * @memberof V20CredRequest
     */
    formats: Array<V20CredFormat>;
    /**
     * Request attachments
     * @type {Array<AttachDecorator>}
     * @memberof V20CredRequest
     */
    requestsattach: Array<AttachDecorator>;
}
/**
 * 
 * @export
 * @interface V20CredRequestFree
 */
export interface V20CredRequestFree {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V20CredRequestFree
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20CredRequestFree
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20CredRequestFree
     */
    connection_id: string;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilterLDProof}
     * @memberof V20CredRequestFree
     */
    filter: V20CredFilterLDProof;
    /**
     * Holder DID to substitute for the credentialSubject.id
     * @type {string}
     * @memberof V20CredRequestFree
     */
    holder_did?: string;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V20CredRequestFree
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20CredRequestRequest
 */
export interface V20CredRequestRequest {
    /**
     * Holder DID to substitute for the credentialSubject.id
     * @type {string}
     * @memberof V20CredRequestRequest
     */
    holder_did?: string;
}
/**
 * 
 * @export
 * @interface V20CredStoreRequest
 */
export interface V20CredStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof V20CredStoreRequest
     */
    credential_id?: string;
}
/**
 * 
 * @export
 * @interface V20DiscoveryExchangeListResult
 */
export interface V20DiscoveryExchangeListResult {
    /**
     * 
     * @type {Array<V20DiscoveryRecord>}
     * @memberof V20DiscoveryExchangeListResult
     */
    results?: Array<V20DiscoveryRecord>;
}
/**
 * 
 * @export
 * @interface V20DiscoveryExchangeResult
 */
export interface V20DiscoveryExchangeResult {
    /**
     * Discover Features v2.0 exchange record
     * @type {V20DiscoveryRecord}
     * @memberof V20DiscoveryExchangeResult
     */
    results?: V20DiscoveryRecord;
}
/**
 * 
 * @export
 * @interface V20DiscoveryRecord
 */
export interface V20DiscoveryRecord {
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    created_at?: string;
    /**
     * Disclosures message
     * @type {Disclosures}
     * @memberof V20DiscoveryRecord
     */
    disclosures?: Disclosures;
    /**
     * Credential exchange identifier
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    discovery_exchange_id?: string;
    /**
     * Queries message
     * @type {Queries}
     * @memberof V20DiscoveryRecord
     */
    queries_msg?: Queries;
    /**
     * Current record state
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    state?: string;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20DiscoveryRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V20DiscoveryRecord
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface V20IssueCredSchemaCore
 */
export interface V20IssueCredSchemaCore {
    /**
     * Whether to remove the credential exchange record on completion (overrides --preserve-exchange-records configuration setting)
     * @type {boolean}
     * @memberof V20IssueCredSchemaCore
     */
    auto_remove?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20IssueCredSchemaCore
     */
    comment?: string;
    /**
     * 
     * @type {V20CredPreview}
     * @memberof V20IssueCredSchemaCore
     */
    credential_preview?: V20CredPreview;
    /**
     * Credential specification criteria by format
     * @type {V20CredFilter}
     * @memberof V20IssueCredSchemaCore
     */
    filter: V20CredFilter;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20IssueCredSchemaCore
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20IssueCredentialModuleResponse
 */
export interface V20IssueCredentialModuleResponse {
}
/**
 * 
 * @export
 * @interface V20Pres
 */
export interface V20Pres {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20Pres
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20Pres
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20Pres
     */
    comment?: string;
    /**
     * Acceptable attachment formats
     * @type {Array<V20PresFormat>}
     * @memberof V20Pres
     */
    formats: Array<V20PresFormat>;
    /**
     * 
     * @type {Array<AttachDecorator>}
     * @memberof V20Pres
     */
    presentationsattach: Array<AttachDecorator>;
}
/**
 * 
 * @export
 * @interface V20PresCreateRequestRequest
 */
export interface V20PresCreateRequestRequest {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V20PresCreateRequestRequest
     */
    auto_verify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V20PresCreateRequestRequest
     */
    comment?: string;
    /**
     * 
     * @type {V20PresRequestByFormat}
     * @memberof V20PresCreateRequestRequest
     */
    presentation_request: V20PresRequestByFormat;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V20PresCreateRequestRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20PresExRecord
 */
export interface V20PresExRecord {
    /**
     * Prover choice to auto-present proof as verifier requests
     * @type {boolean}
     * @memberof V20PresExRecord
     */
    auto_present?: boolean;
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V20PresExRecord
     */
    auto_verify?: boolean;
    /**
     * Attachment content by format for proposal, request, and presentation
     * @type {V20PresExRecordByFormat}
     * @memberof V20PresExRecord
     */
    by_format?: V20PresExRecordByFormat;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20PresExRecord
     */
    connection_id?: string;
    /**
     * Time of record creation
     * @type {string}
     * @memberof V20PresExRecord
     */
    created_at?: string;
    /**
     * Error message
     * @type {string}
     * @memberof V20PresExRecord
     */
    error_msg?: string;
    /**
     * Present-proof exchange initiator: self or external
     * @type {string}
     * @memberof V20PresExRecord
     */
    initiator?: V20PresExRecord.InitiatorEnum;
    /**
     * Presentation message
     * @type {V20Pres}
     * @memberof V20PresExRecord
     */
    pres?: V20Pres;
    /**
     * Presentation exchange identifier
     * @type {string}
     * @memberof V20PresExRecord
     */
    pres_ex_id?: string;
    /**
     * Presentation proposal message
     * @type {V20PresProposal}
     * @memberof V20PresExRecord
     */
    pres_proposal?: V20PresProposal;
    /**
     * Presentation request message
     * @type {V20PresRequest}
     * @memberof V20PresExRecord
     */
    pres_request?: V20PresRequest;
    /**
     * Present-proof exchange role: prover or verifier
     * @type {string}
     * @memberof V20PresExRecord
     */
    role?: V20PresExRecord.RoleEnum;
    /**
     * Present-proof exchange state
     * @type {string}
     * @memberof V20PresExRecord
     */
    state?: V20PresExRecord.StateEnum;
    /**
     * Thread identifier
     * @type {string}
     * @memberof V20PresExRecord
     */
    thread_id?: string;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20PresExRecord
     */
    trace?: boolean;
    /**
     * Time of last record update
     * @type {string}
     * @memberof V20PresExRecord
     */
    updated_at?: string;
    /**
     * Whether presentation is verified: 'true' or 'false'
     * @type {string}
     * @memberof V20PresExRecord
     */
    verified?: V20PresExRecord.VerifiedEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof V20PresExRecord
     */
    verified_msgs?: Array<string>;
}

/**
 * @export
 * @namespace V20PresExRecord
 */
export namespace V20PresExRecord {
    /**
     * @export
     * @enum {string}
     */
    export enum InitiatorEnum {
        Self = <any> 'self',
        External = <any> 'external'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Prover = <any> 'prover',
        Verifier = <any> 'verifier'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        ProposalSent = <any> 'proposal-sent',
        ProposalReceived = <any> 'proposal-received',
        RequestSent = <any> 'request-sent',
        RequestReceived = <any> 'request-received',
        PresentationSent = <any> 'presentation-sent',
        PresentationReceived = <any> 'presentation-received',
        Done = <any> 'done',
        Abandoned = <any> 'abandoned'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VerifiedEnum {
        True = <any> 'true',
        False = <any> 'false'
    }
}
/**
 * 
 * @export
 * @interface V20PresExRecordByFormat
 */
export interface V20PresExRecordByFormat {
    /**
     * 
     * @type {any}
     * @memberof V20PresExRecordByFormat
     */
    pres?: any;
    /**
     * 
     * @type {any}
     * @memberof V20PresExRecordByFormat
     */
    pres_proposal?: any;
    /**
     * 
     * @type {any}
     * @memberof V20PresExRecordByFormat
     */
    pres_request?: any;
}
/**
 * 
 * @export
 * @interface V20PresExRecordList
 */
export interface V20PresExRecordList {
    /**
     * Presentation exchange records
     * @type {Array<V20PresExRecord>}
     * @memberof V20PresExRecordList
     */
    results?: Array<V20PresExRecord>;
}
/**
 * 
 * @export
 * @interface V20PresFormat
 */
export interface V20PresFormat {
    /**
     * Attachment identifier
     * @type {string}
     * @memberof V20PresFormat
     */
    attach_id: string;
    /**
     * Attachment format specifier
     * @type {string}
     * @memberof V20PresFormat
     */
    format: string;
}
/**
 * 
 * @export
 * @interface V20PresProblemReportRequest
 */
export interface V20PresProblemReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V20PresProblemReportRequest
     */
    description: string;
}
/**
 * 
 * @export
 * @interface V20PresProposal
 */
export interface V20PresProposal {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20PresProposal
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20PresProposal
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20PresProposal
     */
    comment?: string;
    /**
     * 
     * @type {Array<V20PresFormat>}
     * @memberof V20PresProposal
     */
    formats: Array<V20PresFormat>;
    /**
     * Attachment per acceptable format on corresponding identifier
     * @type {Array<AttachDecorator>}
     * @memberof V20PresProposal
     */
    proposalsattach: Array<AttachDecorator>;
}
/**
 * 
 * @export
 * @interface V20PresProposalByFormat
 */
export interface V20PresProposalByFormat {
    /**
     * Presentation proposal for DIF
     * @type {DIFProofProposal}
     * @memberof V20PresProposalByFormat
     */
    dif?: DIFProofProposal;
    /**
     * Presentation proposal for indy
     * @type {IndyProofRequest}
     * @memberof V20PresProposalByFormat
     */
    indy?: IndyProofRequest;
}
/**
 * 
 * @export
 * @interface V20PresProposalRequest
 */
export interface V20PresProposalRequest {
    /**
     * Whether to respond automatically to presentation requests, building and presenting requested proof
     * @type {boolean}
     * @memberof V20PresProposalRequest
     */
    auto_present?: boolean;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20PresProposalRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20PresProposalRequest
     */
    connection_id: string;
    /**
     * 
     * @type {V20PresProposalByFormat}
     * @memberof V20PresProposalRequest
     */
    presentation_proposal: V20PresProposalByFormat;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V20PresProposalRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20PresRequest
 */
export interface V20PresRequest {
    /**
     * Message identifier
     * @type {string}
     * @memberof V20PresRequest
     */
    id?: string;
    /**
     * Message type
     * @type {string}
     * @memberof V20PresRequest
     */
    type?: string;
    /**
     * Human-readable comment
     * @type {string}
     * @memberof V20PresRequest
     */
    comment?: string;
    /**
     * 
     * @type {Array<V20PresFormat>}
     * @memberof V20PresRequest
     */
    formats: Array<V20PresFormat>;
    /**
     * Attachment per acceptable format on corresponding identifier
     * @type {Array<AttachDecorator>}
     * @memberof V20PresRequest
     */
    request_presentationsattach: Array<AttachDecorator>;
    /**
     * Whether verifier will send confirmation ack
     * @type {boolean}
     * @memberof V20PresRequest
     */
    will_confirm?: boolean;
}
/**
 * 
 * @export
 * @interface V20PresRequestByFormat
 */
export interface V20PresRequestByFormat {
    /**
     * Presentation request for DIF
     * @type {DIFProofRequest}
     * @memberof V20PresRequestByFormat
     */
    dif?: DIFProofRequest;
    /**
     * Presentation request for indy
     * @type {IndyProofRequest}
     * @memberof V20PresRequestByFormat
     */
    indy?: IndyProofRequest;
}
/**
 * 
 * @export
 * @interface V20PresSendRequestRequest
 */
export interface V20PresSendRequestRequest {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V20PresSendRequestRequest
     */
    auto_verify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V20PresSendRequestRequest
     */
    comment?: string;
    /**
     * Connection identifier
     * @type {string}
     * @memberof V20PresSendRequestRequest
     */
    connection_id: string;
    /**
     * 
     * @type {V20PresRequestByFormat}
     * @memberof V20PresSendRequestRequest
     */
    presentation_request: V20PresRequestByFormat;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V20PresSendRequestRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20PresSpecByFormatRequest
 */
export interface V20PresSpecByFormatRequest {
    /**
     * Optional Presentation specification for DIF, overrides the PresentionExchange record's PresRequest
     * @type {DIFPresSpec}
     * @memberof V20PresSpecByFormatRequest
     */
    dif?: DIFPresSpec;
    /**
     * Presentation specification for indy
     * @type {IndyPresSpec}
     * @memberof V20PresSpecByFormatRequest
     */
    indy?: IndyPresSpec;
    /**
     * Record trace information, based on agent configuration
     * @type {boolean}
     * @memberof V20PresSpecByFormatRequest
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface V20PresentProofModuleResponse
 */
export interface V20PresentProofModuleResponse {
}
/**
 * 
 * @export
 * @interface V20PresentationSendRequestToProposal
 */
export interface V20PresentationSendRequestToProposal {
    /**
     * Verifier choice to auto-verify proof presentation
     * @type {boolean}
     * @memberof V20PresentationSendRequestToProposal
     */
    auto_verify?: boolean;
    /**
     * Whether to trace event (default false)
     * @type {boolean}
     * @memberof V20PresentationSendRequestToProposal
     */
    trace?: boolean;
}
/**
 * 
 * @export
 * @interface VCRecord
 */
export interface VCRecord {
    /**
     * 
     * @type {Array<string>}
     * @memberof VCRecord
     */
    contexts?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VCRecord
     */
    cred_tags?: { [key: string]: string; };
    /**
     * (JSON-serializable) credential value
     * @type {any}
     * @memberof VCRecord
     */
    cred_value?: any;
    /**
     * 
     * @type {Array<string>}
     * @memberof VCRecord
     */
    expanded_types?: Array<string>;
    /**
     * Credential identifier
     * @type {string}
     * @memberof VCRecord
     */
    given_id?: string;
    /**
     * Issuer identifier
     * @type {string}
     * @memberof VCRecord
     */
    issuer_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VCRecord
     */
    proof_types?: Array<string>;
    /**
     * Record identifier
     * @type {string}
     * @memberof VCRecord
     */
    record_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VCRecord
     */
    schema_ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VCRecord
     */
    subject_ids?: Array<string>;
}
/**
 * 
 * @export
 * @interface VCRecordList
 */
export interface VCRecordList {
    /**
     * 
     * @type {Array<VCRecord>}
     * @memberof VCRecordList
     */
    results?: Array<VCRecord>;
}
/**
 * 
 * @export
 * @interface VerifyRequest
 */
export interface VerifyRequest {
    /**
     * Signed document
     * @type {SignedDoc}
     * @memberof VerifyRequest
     */
    doc: SignedDoc;
    /**
     * Verkey to use for doc verification
     * @type {string}
     * @memberof VerifyRequest
     */
    verkey?: string;
}
/**
 * 
 * @export
 * @interface VerifyResponse
 */
export interface VerifyResponse {
    /**
     * Error text
     * @type {string}
     * @memberof VerifyResponse
     */
    error?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyResponse
     */
    valid: boolean;
}
/**
 * 
 * @export
 * @interface W3CCredentialsListRequest
 */
export interface W3CCredentialsListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof W3CCredentialsListRequest
     */
    contexts?: Array<string>;
    /**
     * Given credential id to match
     * @type {string}
     * @memberof W3CCredentialsListRequest
     */
    given_id?: string;
    /**
     * Credential issuer identifier to match
     * @type {string}
     * @memberof W3CCredentialsListRequest
     */
    issuer_id?: string;
    /**
     * Maximum number of results to return
     * @type {number}
     * @memberof W3CCredentialsListRequest
     */
    max_results?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof W3CCredentialsListRequest
     */
    proof_types?: Array<string>;
    /**
     * Schema identifiers, all of which to match
     * @type {Array<string>}
     * @memberof W3CCredentialsListRequest
     */
    schema_ids?: Array<string>;
    /**
     * Subject identifiers, all of which to match
     * @type {Array<string>}
     * @memberof W3CCredentialsListRequest
     */
    subject_ids?: Array<string>;
    /**
     * Tag filter
     * @type {{ [key: string]: string; }}
     * @memberof W3CCredentialsListRequest
     */
    tag_query?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof W3CCredentialsListRequest
     */
    types?: Array<string>;
}
/**
 * 
 * @export
 * @interface WalletModuleResponse
 */
export interface WalletModuleResponse {
}
/**
 * 
 * @export
 * @interface WriteLedgerRequest
 */
export interface WriteLedgerRequest {
    /**
     * 
     * @type {string}
     * @memberof WriteLedgerRequest
     */
    ledger_id?: string;
}