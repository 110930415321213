import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{m: 3}}>
      {'Copyright © '}
      <Link color="inherit" href="https://remppavasara.azurewebsites.net/">
        {t("app.header.title")}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;