import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Step, StepLabel, Stepper, Typography } from '@mui/material';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Receipt from './Receipt';
import Review from './Review';
import * as StoreWalletApi from '../api/StoreWalletApi';
import { AppStateContext } from '../state/AppStateContext';
import { EReceiptIssuanceResponse, PaymentDetails, Purchase, ShippingAddress } from '../models/PurchaseModels';
import Card from './common/Card';

const dummyAddress: ShippingAddress = {
  firstName: "Rami",
  lastName: "Rakentaja",
  addressLine1: "Rakentajankatu 1",
  zipCode: "37630",
  city: "Valkeakoski",
  country: "Finland"
}

const dummyPayment: PaymentDetails = {
  paymentMeansCode: "30",
  nameOnCard: "Rami Rakentaja",
  cardNumber: "1111 2222 3333 4444",
  expiryDate: "10/26",
  cvv: "123"
}

const initPurchase: () => Purchase = () => {
  return {
    items: [
      {
        product: {
          sku: "hammer",
          name: "Hammer",
          grossPrice: "19.95",
          vatPercentage: "24"
        },
        amount: 1
      },
      {
        product: {
          sku: "handsaw",
          name: "Hand saw",
          grossPrice: "9.95",
          vatPercentage: "24"
        },
        amount: 1
      }
    ],
    shippingAddress: dummyAddress,
    paymentDetails: dummyPayment
  };
}

export enum ConnectionKeyType {
  CONNECTION_ID = "ConnectionId",
  WEB_DID = "WebDid",
  OIDC_WALLET_URL = "OIDCWalletUrl"
}

export type ConnectionKey = {
  type: ConnectionKeyType,
  key: string
}

const Checkout: React.FC = () => {
  const { t, i18n } = useTranslation(["translation"]);
  const [activeStep, setActiveStep] = React.useState(0);
  const appContext = React.useContext(AppStateContext);
  const [purchase, setPurchase] = useState<Purchase>(initPurchase());
  const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [credentialId, setCredentialId] = useState<string|undefined>(undefined);
  const steps = [
    "AddressForm",
    "PaymentForm",
    "Review", 
    "Receipt"
  ];

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const sendReceipt = async (connKey: ConnectionKey): Promise<string | null> => {
    const _send = (connKey: ConnectionKey) => {
      switch (connKey.type) {
        case ConnectionKeyType.CONNECTION_ID:
          setSubmitting(true);
          return StoreWalletApi.SubmitPurchase(connKey.key, purchase);
        case ConnectionKeyType.OIDC_WALLET_URL:
          return StoreWalletApi.CreateOIDCIssuanceRequest(purchase);
        case ConnectionKeyType.WEB_DID:
          setSubmitting(true);
          return StoreWalletApi.SendReceiptWithWebDid(connKey.key, purchase);
      }
    }
    appContext.setWalletError();
    return _send(connKey)
    .then(res => {
      console.log("sendReceipt res", res);
      if (res.status === 200) {
        const resData = res.data as EReceiptIssuanceResponse;
        if (connKey.type === ConnectionKeyType.OIDC_WALLET_URL && resData) {
          appContext.setOIDCCredentialIssuanceRequest(resData.issuanceRequest!);
        }
        return resData?.credentialId;
      }
      else if (res.status === 400) {
        const resContent = res.data as string;
        appContext.setWalletError(res.data as string);
        return null;
      } else {
        if (typeof res.data === "string") {
          appContext.setWalletError(res.data as string);
        }
        return null;
      }
    })
    .catch(err => {
      appContext.setWalletError("FailedToSendEReceipt");
      return null;
    })
    .finally(() => setSubmitting(false));
  }

  const onReceiptSent = (credId: string): void => {
    setCredentialId(() => credId);
    handleNext();
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <AddressForm purchase={purchase} setPurchase={setPurchase} disabled />;
      case 1:
        return <PaymentForm purchase={purchase} setPurchase={setPurchase} disabled />;
      case 2:
        return <Review purchase={purchase} />;
      default:
        return <Receipt onSendReceipt={sendReceipt} onReceiptSent={onReceiptSent}/>;
    }
  }

  const reset = () => {
    appContext.resetWalletState();
    setCredentialId(() => undefined);
    setPurchase(initPurchase());
    setActiveStep(0);
  };

  const LabelTitle: React.FC<{label: string}> = ({label}) => {
    return windowWidth > 600 ?
    <StepLabel>{t(`app.components.${label}.title`)}</StepLabel> :
    <StepLabel>{i18n.exists(`app.components.${label}.title2`) ? t(`app.components.${label}.title2`) : t(`app.components.${label}.title`)}</StepLabel>
  }

  return (
    <Card title={t("app.components.Checkout.title")}>
      <Stepper alternativeLabel activeStep={activeStep} sx={{ pt: 1, pb: 2, mt: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <LabelTitle label={label} />
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
              {t("app.components.Checkout.confirmed")}
            </Typography>
            <Typography variant="subtitle1">
              {t("app.components.Checkout.confirmedMsg") + credentialId}
            </Typography>
            <Typography variant="subtitle1">
              {t("app.components.Checkout.eReceiptSentMsg")}
            </Typography>
          </Container>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              onClick={reset}
              sx={{ mt: 3, ml: 1 }}
            >
              {t("app.components.Checkout.title")}
            </Button>
          </Box>
        </>
      ) : (
        <>
          { submitting &&
            <Container maxWidth="sm">
              <Alert severity="info" icon={<CircularProgress />} >
                <AlertTitle>{t("app.components.Checkout.submittingTitle")}</AlertTitle>
                {t("app.components.Checkout.submittingMsg")}
              </Alert>
            </Container>
          }
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {activeStep !== 0 && activeStep < steps.length - 1 && (
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                {t("app.components.Checkout.previousStep")}
              </Button>
            )}
            {activeStep < steps.length -1 &&
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 2 ? t("app.components.Checkout.confirm") : t("app.components.Checkout.nextStep")}
              </Button>
            }
          </Box>
        </>
      )}
    </Card>
  );
}

export default Checkout;