/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, FormHelperText, TextField, Tooltip, Typography } from '@mui/material';
import { AppStateContext } from '../state/AppStateContext';
import QRCode from 'qrcode.react';
import { Paragraph, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as AcaPyTypes from '../models/AcaPyModels';
import { FlexColTight } from './common/CommonStyles';
import { ConnectionKey, ConnectionKeyType } from './Checkout';
import * as StoreWalletApi from '../api/StoreWalletApi';
import useCountdown from '../utils/useCountdown';

const Line = styled(Paragraph)`
  text-align: center;
  border-bottom: 1px solid ${tokens.colors.depthBase};
  line-height: 0.1em;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const Span = styled.span`
  background: ${tokens.colors.whiteBase};
  padding: 0 10px;
  color: ${tokens.colors.depthDark1};
  font-size: 0.9rem;
  margin-right: 0%;
  font-weight: 500;
`

enum WalletType {
  PERSONAL_ARIES,
  PERSONAL_OIDC,
  COMPANY
}

const ConnStateContent: React.FC<{connection: AcaPyTypes.ConnRecord}> = ({connection}) => {
  const { t } = useTranslation(["translation"]);
  switch (connection.state) {
    case "active":
      return (
        <Alert severity="success" >
          <AlertTitle>{t("app.components.Receipt.connectionCreatedTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionCreatedMsg")}
        </Alert>
      );
    case "abandoned":
      return (
        <Alert severity="error">
          <AlertTitle>{t("app.components.Receipt.errorTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionErrorMsg")}
        </Alert>
      );
    default:
      return (
        <Alert severity="info" icon={<CircularProgress />} >
          <AlertTitle>{t("app.components.Receipt.connectionPendingTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionPendingMsg")}
        </Alert>
      );
  }
}

const Invitation: React.FC<{invitation: AcaPyTypes.InvitationResult}> = ({invitation}) => {
  const { t } = useTranslation(["translation"]);
  const [showInvitationUrl, setShowInvitationUrl] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  }

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
        {t("app.components.Receipt.scanInvitationHelpText")}
      </Typography>
      <Box sx={{m: "auto"}}>
        <QRCode size={250} value={invitation.invitation_url!} style={{marginRight: `${tokens.spacing.xs}`}}/>
      </Box>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Typography variant="body1" gutterBottom sx={{m: "auto"}}>
        {t("app.components.Receipt.copyInvitationHelpText")}
        <Button variant="text" onClick={() => setShowInvitationUrl(old => !old)}>
          { showInvitationUrl ?
            t("app.components.Receipt.hideInvitationUrl") :
            t("app.components.Receipt.showInvitationUrl")
          }
        </Button>
      </Typography>
      { showInvitationUrl && 
        <TextField multiline fullWidth variant={"filled"} value={invitation.invitation_url!} style={{backgroundColor: `${tokens.colors.whiteBase}`}} sx={{m: 0}}/>
      }
      <Button
        variant="contained"
        onClick={() => copyToClipboard(invitation.invitation_url!)}
        sx={{ mt: 1, mx: "auto" }}
      >{isCopied ? t("app.components.Receipt.urlCopied") : t("app.components.Receipt.copyUrl")}</Button>
    </FlexColTight>
  )
}

const OIDCCredentialIssuanceRequest: React.FC<{issuanceReq: string}> = ({issuanceReq}) => {
  const { t } = useTranslation(["translation"]);
  const [showIssuanceReq, setShowIssuanceReq] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  }

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
        {t("app.components.Receipt.OIDCCredentialIssuanceRequest.scanIssuanceReqHelpText")}
      </Typography>
      <Box sx={{m: "auto"}}>
        <QRCode size={250} value={issuanceReq} style={{marginRight: `${tokens.spacing.xs}`}}/>
      </Box>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Typography variant="body1" gutterBottom sx={{m: "auto"}}>
        {t("app.components.Receipt.OIDCCredentialIssuanceRequest.copyIssuanceReqHelpText")}
        <Button variant="text" onClick={() => setShowIssuanceReq(old => !old)}>
          { showIssuanceReq ?
            t("app.components.Receipt.OIDCCredentialIssuanceRequest.hideIssuanceReqUrl") :
            t("app.components.Receipt.OIDCCredentialIssuanceRequest.showIssuanceReqUrl")
          }
        </Button>
      </Typography>
      { showIssuanceReq && 
        <TextField multiline fullWidth variant={"filled"} value={issuanceReq} style={{backgroundColor: `${tokens.colors.whiteBase}`}} sx={{m: 0}}/>
      }
      <Button
        variant="contained"
        onClick={() => copyToClipboard(issuanceReq)}
        sx={{ mt: 1, mx: "auto" }}
      >{isCopied ? t("app.components.Receipt.urlCopied") : t("app.components.Receipt.copyUrl")}</Button>
    </FlexColTight>
  )
}

const CountdownRedirect: React.FC<{redirectUrl: string}> = ({redirectUrl}) => {
  const { t } = useTranslation(["translation"]);
  const countdown = useCountdown(5);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = redirectUrl;
    }
  })
  return (
    <FlexColTight>
      <Alert severity="success" >
        <AlertTitle>{t("app.components.Receipt.CountdownRedirect.alertTitle")}</AlertTitle>
        {t("app.components.Receipt.CountdownRedirect.alertMsg") + " " + countdown}
      </Alert>
    </FlexColTight>
  );
}

const SelectConnectionMethod: React.FC<{onSelect: (walletType: WalletType) => void}> = ({onSelect}) => {
  const { t } = useTranslation(["translation"]);
  
  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{pb: 2}}>
        {t("app.components.Receipt.selectConnectionMethodHelpText_part1")}
        <Tooltip title={"Verifiable credential, Verifiable attestation"} placement='top'><span css={{borderBottom: "1px dotted black"}}>{t("app.components.Receipt.selectConnectionMethodHelpText_part2")}</span></Tooltip>
        {t("app.components.Receipt.selectConnectionMethodHelpText_part3")}
      </Typography>
      <Typography variant="button" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToPersonalAriesWalletLabel")}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToPersonalAriesWalletInfo")}
      </Typography>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(WalletType.PERSONAL_ARIES)}>
        {t("app.components.Receipt.sendToPersonalAriesWalletBtn")}
      </Button>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Typography variant="button" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToPersonalOIDCWalletLabel")}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToPersonalOIDCWalletInfo")}
      </Typography>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(WalletType.PERSONAL_OIDC)}>
        {t("app.components.Receipt.sendToPersonalOIDCWalletBtn")}
      </Button>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Typography variant="button" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToCompanyWalletLabel")}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{m: "auto", textAlign: "center"}}>
        {t("app.components.Receipt.sendToCompanyWalletInfo")}
      </Typography>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(WalletType.COMPANY)}>
        {t("app.components.Receipt.sendToCompanyWalletBtn")}
      </Button>
    </FlexColTight>
  );
}

const CreateNewConnection: React.FC<{onSendReceipt: (connKey: ConnectionKey) => Promise<string | null>, onReceiptSent: (credId: string) => void}> = ({onSendReceipt, onReceiptSent}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);

  useEffect(() => {
    if (!appContext.invitation) {
      appContext.createInvitationAsync();
    }
    if (appContext.connection) {
      onSendReceipt({type: ConnectionKeyType.CONNECTION_ID, key: appContext.connection.connection_id!})
      .then(res => {
        if (res !== null) {
          onReceiptSent(res);
        }
      });
    }
  }, [appContext.connection]);

  if (appContext.connection) {
    return <ConnStateContent connection={appContext.connection} />;
  }
  else if (appContext.invitation) {
    return <Invitation invitation={appContext.invitation} />;
  }
  return (
    <Alert severity="info" icon={<CircularProgress />} >
      <AlertTitle>{t("app.components.Receipt.creatingInvitationTitle")}</AlertTitle>
      {t("app.components.Receipt.creatingInvitationMsg")}
    </Alert>
  );
}

const SendToPersonalOIDCWallet: React.FC<{onSendReceipt: (connKey: ConnectionKey) => Promise<string | null>, onReceiptSent: (credId: string) => void}> = ({onSendReceipt, onReceiptSent}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);
  const [redirectUrl, setRedirectUrl] = useState<string|undefined>(undefined);

  useEffect(() => {
    if (!appContext.oidcCredentialIssuanceRequest) {
      onSendReceipt({type: ConnectionKeyType.OIDC_WALLET_URL, key: ""});
    }
    if (appContext.oidcCredentialIssuanceRequest?.startsWith("http")) {
      window.location.href = appContext.oidcCredentialIssuanceRequest;
    }
  }, [appContext.oidcCredentialIssuanceRequest]);

  const onConfirm = (values: {eAddress: string}) => {
    StoreWalletApi.ResolveOIDCIssuanceEndpoint(values.eAddress)
    .then(res => {
      if (res.data) {
        const issuanceEndpointUrl = appContext.oidcCredentialIssuanceRequest?.replace("openid-initiate-issuance://", res.data as string);
        console.log("Redirect to " + issuanceEndpointUrl);
        setRedirectUrl(() => issuanceEndpointUrl);
      }
    })
    onSendReceipt({type: ConnectionKeyType.OIDC_WALLET_URL, key: values.eAddress});
  }

  const formik = useFormik({
    initialValues: { eAddress: "https://waltid.minisuomi.net/" },
    validationSchema: Yup.object({
      eAddress: Yup.string().required('Required')
    }),
    onSubmit: onConfirm
  });

  if (redirectUrl) {
    return <CountdownRedirect redirectUrl={redirectUrl} />;
  }
  if (appContext.oidcCredentialIssuanceRequest) {
    return (
      <FlexColTight>
        <OIDCCredentialIssuanceRequest issuanceReq={appContext.oidcCredentialIssuanceRequest} />
        <Line>
          <Span>{t("app.components.Receipt.OR")}</Span>
        </Line>
        <Typography variant="button" gutterBottom sx={{m: "auto", textAlign: "center"}}>
          {t("app.components.Receipt.OIDCCredentialIssuanceRequest.sendToWebWalletLabel")}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{m: "auto", textAlign: "center"}}>
          {t("app.components.Receipt.OIDCCredentialIssuanceRequest.sendToWebWalletInfo")}
        </Typography>
        <TextField
          name="eAddress"
          label="eAddress"
          value={formik.values.eAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.eAddress) && Boolean(formik.errors.eAddress)}
          helperText={Boolean(formik.touched.eAddress) && formik.errors.eAddress}
        />
        <Button
          variant="contained"
          onClick={formik.submitForm}
          sx={{ mt: 1 }}
          disabled={formik.isSubmitting}
        >
          {t("app.components.Receipt.confirm")}
        </Button>
      </FlexColTight>
    )
  }

  return (
    <Alert severity="info" icon={<CircularProgress />} >
      <AlertTitle>{t("app.components.Receipt.OIDCCredentialIssuanceRequest.creatingIssuanceRequestTitle")}</AlertTitle>
      {t("app.components.Receipt.OIDCCredentialIssuanceRequest.creatingIssuanceRequestMsg")}
    </Alert>
  );
}

const SendToCompanyWallet: React.FC<{onSendReceipt: (connKey: ConnectionKey) => Promise<string | null>, onReceiptSent: (credId: string) => void}> = ({onSendReceipt, onReceiptSent}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);

  useEffect(() => {
    if (appContext.connection) {
      onSendReceipt({type: ConnectionKeyType.CONNECTION_ID, key: appContext.connection.connection_id!})
      .then(res => {
        if (res !== null) {
          onReceiptSent(res);
        }
      });
    }
  }, [appContext.connection]);

  const onConfirm = async (values: {eAddress: string}): Promise<any> => {
    appContext.setWalletError();
    if (values.eAddress.startsWith("did:sov:")) {
      setConnecting(true);
      return appContext.getCreatePublicDidConnection(values.eAddress)
      .finally(() => setConnecting(false));
    }
    else if (values.eAddress.startsWith("did:web:")) {
      return onSendReceipt({type: ConnectionKeyType.WEB_DID, key: values.eAddress})
      .then(res => {
        if (res !== null) {
          onReceiptSent(res);
        }
      });
    }
  }

  const formik = useFormik({
    initialValues: { eAddress: "" },
    validationSchema: Yup.object({
      eAddress: Yup.string().test("prefix", "didPrefixError", ((val, ctx) => {
        if (!val) return true;
        const valToLower = val.toLowerCase();
        if (valToLower.startsWith("did:sov:")) {
          return valToLower.replace("did:sov:", "").length > 0;
        }
        else if (valToLower.startsWith("did:web:")) {
          return valToLower.replace("did:web:", "").length > 0;
        }
        return false;
      })).required('Required')
    }),
    onSubmit: onConfirm
  });

  const getErrorMessage = (errorKey?: string) => {
    if (errorKey === "didPrefixError") {
      return t("app.components.Receipt.didPrefixError");
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
        {t("app.components.Receipt.sendToCompanyWalletHelpText")}
        <Button variant="text" onClick={() => setShowMoreInfo(old => !old)}>
          { showMoreInfo ?
            t("app.components.Receipt.sendToCompanyWalletMoreInfoCloseBtn") :
            t("app.components.Receipt.sendToCompanyWalletMoreInfoOpenBtn")
          }
        </Button>
      </Typography>
      { showMoreInfo && 
        <Container sx={{mb: 2, mt: 0, backgroundColor: `${tokens.colors.depthLight1}`}}>
          <Typography variant="body1" gutterBottom sx={{m: "auto", py: 1}}>
            {t("app.components.Receipt.sendToCompanyWalletAriesInfo")}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{m: "auto", py: 1}}>
            {t("app.components.Receipt.sendToCompanyWalletOIDCInfo")}
          </Typography>
        </Container>
      }
      { connecting &&
        <Alert severity="info" icon={<CircularProgress />} sx={{my: 2}}>
          <AlertTitle>{t("app.components.Receipt.connectionPendingTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionPendingMsg")}
        </Alert>
      }
      { appContext.walletError && 
        <Alert severity="error" sx={{my: 2}}>
          <AlertTitle>{t("app.components.Receipt.errorTitle")}</AlertTitle>
          {t(`app.components.AppStateContext.WalletError.${appContext.walletError}`)}
        </Alert>
      }
      <TextField
        name="eAddress"
        label="Public DID"
        value={formik.values.eAddress}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.eAddress) && Boolean(formik.errors.eAddress)}
        placeholder={t("app.components.Receipt.sendToCompanyWalletPlaceholder")}
      />
      { Boolean(formik.touched.eAddress) && formik.errors.eAddress &&
        <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(formik.errors.eAddress)}</FormHelperText> 
      }
      <Button
        variant="contained"
        onClick={formik.submitForm}
        sx={{ mt: 1 }}
        disabled={formik.isSubmitting}
      >{t("app.components.Receipt.confirm")}</Button>
    </FlexColTight>
  );
}

const Receipt: React.FC<{onSendReceipt: (connKey: ConnectionKey) => Promise<string | null>, onReceiptSent: (credId: string) => void}> = ({onSendReceipt, onReceiptSent}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);
  const [walletType, setWalletType] = useState<WalletType | undefined>(undefined);

  const selectWalletType = (method: WalletType) => {
    setWalletType(method);
  }

  const resetWalletType = () => {
    setWalletType(undefined);
    appContext.resetWalletState();
  }
  
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.Receipt.title")}
      </Typography>
      <>
        { (() => {
          switch (walletType) {
            case WalletType.PERSONAL_ARIES:
              return <CreateNewConnection onSendReceipt={onSendReceipt} onReceiptSent={onReceiptSent}/>;
            case WalletType.PERSONAL_OIDC:
              return <SendToPersonalOIDCWallet onSendReceipt={onSendReceipt} onReceiptSent={onReceiptSent}/>;
            case WalletType.COMPANY:
              return <SendToCompanyWallet onSendReceipt={onSendReceipt} onReceiptSent={onReceiptSent}/>;
            default:
              return <SelectConnectionMethod onSelect={selectWalletType}/>;
        }})()}
        { walletType !== undefined &&
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => resetWalletType()}
            >
              {t("common.actions.cancel.title")}
            </Button>
          </Box>
        }
      </>
    </Container>
  );
}

export default Receipt;