import React, { useEffect, useState, PropsWithChildren } from "react";
import log from "loglevel";
import _ from "lodash";
import * as StoreWalletApi from '../api/StoreWalletApi';
import * as AcaPyTypes from '../models/AcaPyModels';
import useInterval from "../utils/useInterval";
import { EReceiptIssuanceResponse, Purchase } from "../models/PurchaseModels";

/* Connection/invitation value:
 * undefined => Not fetched from api or an error happened when requesting from api
 * null => Api returned null value (item does not exist in the api / wallet)
 */

interface AppState {
  isLoadingApp: boolean,
  invitation?: AcaPyTypes.InvitationResult|null,
  oobInvitation?: AcaPyTypes.InvitationRecord|null,
  connection?: AcaPyTypes.ConnRecord|null,
  credentialExchanges: AcaPyTypes.V10CredentialExchange[]|undefined|null,
  eReceiptCredEx?: AcaPyTypes.V10CredentialExchange|null,
  oidcCredentialIssuanceRequest?: string|null,
  walletError?: string
}

export type AppStateType = AppState & {
  // Functions
  loadAppStateAsync: () => Promise<void>,
  createInvitationAsync: () => Promise<AcaPyTypes.InvitationResult|undefined|null>,
  createOOBInvitationAsync: () => Promise<AcaPyTypes.InvitationRecord|undefined|null>,
  getCredentialExchangesAsync: () => Promise<AcaPyTypes.V10CredentialExchange[]|undefined|null>,
  setConnection: (connection: AcaPyTypes.ConnRecord|undefined|null) => void,
  getCreatePublicDidConnection: (did: string) => Promise<AcaPyTypes.InvitationResult|undefined|null>,
  // getConnectionAsync: () => Promise<void>,
  // getCredentialExchangesAsync: () => Promise<void>,
  setOIDCCredentialIssuanceRequest: (issuanceRequest: string) => void,
  createOIDCCredentialIssuanceRequestAsync: (purchase: Purchase) => Promise<string|undefined|null>,
  refreshWalletState: () => Promise<void>,
  resetWalletState: () => Promise<void>,
  setWalletError: (error?: string) => void
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  invitation: undefined,
  connection: undefined,
  credentialExchanges: [],
  eReceiptCredEx: {},
  oidcCredentialIssuanceRequest: undefined,
  walletError: undefined,
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  loadAppStateAsync: () => new Promise<void>(resolve => resolve()),
  createInvitationAsync: () => new Promise<AcaPyTypes.InvitationResult|undefined|null>(resolve => resolve(null)),
  createOOBInvitationAsync: () => new Promise<AcaPyTypes.InvitationRecord|undefined|null>(resolve => resolve(null)),
  getCredentialExchangesAsync: () => new Promise<AcaPyTypes.V10CredentialExchange[]|undefined|null>(resolve => resolve(null)),
  setConnection: (connection: AcaPyTypes.ConnRecord|undefined|null) => { return; },
  getCreatePublicDidConnection: (did: string) => new Promise<AcaPyTypes.InvitationResult|undefined|null>(resolve => resolve(null)),
  // getConnectionAsync: () => new Promise<void>(resolve => resolve()),
  // getCredentialExchangesAsync: () => new Promise<void>(resolve => resolve()),
  setOIDCCredentialIssuanceRequest: (issuanceRequest: string) => { return; },
  createOIDCCredentialIssuanceRequestAsync: (purchase: Purchase) => new Promise<string|undefined|null>(resolve => resolve(null)),
  refreshWalletState: () => new Promise<void>(resolve => resolve()),
  resetWalletState: () => new Promise<void>(resolve => resolve()),
  setWalletError: (error?: string) => { return; },
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

// An alternative way to initialize a default AppStateContext would be the following.
// export const AppStateContext = React.createContext<AppStateType>({} as AppStateType);
// Here we skip creating dummy context by telling to Typescript compiler that an empty object is a valid AppStateType.
// If you do always make sure to only access the context inside of TodoContextProvider with useContext 
// then you can safely skip initialising AppStateType inside of createContext because that initial value 
// will never actually be accessed.

enum PromiseTypes {
  INVITATION = "invitation",
  OOB_INVITATION = "oobInvitation",
  CONNECTION = "connection",
  CREDENTIAL_EXCHANGES = "credentialExchanges",
  PUBLIC_DID_CONNECTION = "publicDidConnection",
  OIDC_CRED_ISSUANCE_REQ = "oidcCredentialIssuanceRequest"
}

/**
 * AppContextProvider contains "public" getter methods and "private" fetch methods e.g. getCompanyAsync and fetchCompany
 * When fetching data from APIs, ongoing request promises are stored in the *promises* state. Getter methods (e.g. getCompanyAsync) 
 * return the existing request promise if one exists. If it does not exist, it calls fetch method (e.g. fetchCompany) 
 * to create a new request promise. The idea is to prevent concurrent requests to fetch the same data.
 * Methods that have a comment "public" are accessible outside of the AppContextProvider.
 * Methods that have a comment "private" are for internal use of the AppContextProvider only.
 */
const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  const [refreshWalletInterval, setRefreshWalletInterval] = useState<boolean>(false);
  const [refreshCredExsInterval, setRefreshCredExsInterval] = useState<boolean>(false);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  useEffect(() => {
    loadAppStateAsync();
  }, []);

  // useEffect(() => {
  //   if (appState.connection?.state === "active") {
  //     getCredentialExchangesAsync();
  //   }
  // }, [appState.connection]);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }
  
  // public
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    // Reset necessary stuff in app state
    setAppState(oldState => ({...oldState, isLoadingApp: true}));
    // Fetch/load necessary stuff asynchronously
    const loadWalletStatePromise = loadWalletStateAsync();

    return Promise.all([loadWalletStatePromise])
    .then(() => {
      logger.debug("ACP loadAppStateAsync finished");
      setAppState(oldState => ({...oldState, isLoadingApp: false}));
      return;
    });
  }

  // public
  const setConnection = (connection: AcaPyTypes.ConnRecord|undefined|null) => {
    setAppState(oldState => ({...oldState, connection}));
  }

  // public
  const getCreatePublicDidConnection = async (did: string): Promise<AcaPyTypes.InvitationResult|undefined|null> => {
    const promise = StoreWalletApi.GetCreatePublicDidConnection(did)
    .then(res => {
      console.log("Public DID connection response", res);
      if (res.data) {
        setAppState(oldState => ({...oldState, connection: res.data}));
        setRefreshWalletInterval(res.data.state !== "active");
        return res.data;
      }
      return null;
    })
    .catch(err => {
      logger.error("ACP getCreatePublicDidConnection error", err);
      setAppState(oldState => ({...oldState, walletError: "PublicDidConnectionError"}));
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.PUBLIC_DID_CONNECTION));
    addPromise(PromiseTypes.PUBLIC_DID_CONNECTION, promise);
    return promise;
  }

  const loadWalletStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadWalletStateAsync");
    if (appState.invitation) {
      await getConnectionAsync(appState.invitation.connection_id!);
    }
    if (appState.connection) {
      await getConnectionAsync(appState.connection.connection_id!);
    }
  }

  const createInvitationAsync = async (): Promise<AcaPyTypes.InvitationResult|undefined|null> => {
    const promise = StoreWalletApi.CreateInvitation()
    .then(res => {
      if (res.data) {
        const invitation = res.data;
        setAppState(oldState => ({...oldState, invitation}));
        // Activate useInterval to refresh wallet to check user 
        // if has accepted the connection invitation.
        setRefreshWalletInterval(true);
        return invitation;
      }
      setAppState(oldState => ({...oldState, invitation: null}));
      return null;
    })
    .catch(err => {
      logger.error("ACP createInvitation error", err);
      setAppState(oldState => ({...oldState, walletError: "InvitationError"}));
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.INVITATION));
    addPromise(PromiseTypes.INVITATION, promise);
    return promise;
  }

  const createOOBInvitationAsync = async (): Promise<AcaPyTypes.InvitationRecord|undefined|null> => {
    const promise = StoreWalletApi.CreateOOBInvitation()
    .then(res => {
      if (res.data) {
        const oobInvitation = res.data;
        setAppState(oldState => ({...oldState, oobInvitation}));
        // Activate useInterval to refresh wallet to check user 
        // if has accepted the connection invitation.
        setRefreshWalletInterval(true);
        return oobInvitation;
      }
      setAppState(oldState => ({...oldState, oobInvitation: null}));
      return null;
    })
    .catch(err => {
      logger.error("ACP createOOBInvitationAsync error", err);
      setAppState(oldState => ({...oldState, walletError: "InvitationError"}));
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.OOB_INVITATION));
    addPromise(PromiseTypes.OOB_INVITATION, promise);
    return promise;
  }

  // public
  const getConnectionAsync = async (connectionId: string): Promise<AcaPyTypes.ConnRecord[]> => {
    return promises[PromiseTypes.CONNECTION] ?? fetchConnection(connectionId);
  }

  // private
  const fetchConnection = async (connectionId: string): Promise<AcaPyTypes.ConnRecord|null> => {
    const promise = StoreWalletApi.GetConnection(connectionId)
    .then(res => {
      console.log("Connection", res);
      if (res.data) {
        if (res.data.state !== "invitation") {
          setAppState(oldState => ({...oldState, connection: res.data}));
        }
        if (res.data.state === "active") {
          setRefreshWalletInterval(false);
        }
        return res.data;
      }
      return null;
    })
    .catch(err => {
      return null;
    })
    .finally(() => removePromise(PromiseTypes.CONNECTION));
    addPromise(PromiseTypes.CONNECTION, promise);
    return promise;
  }

  const getCredentialExchangesAsync = async (): Promise<AcaPyTypes.V10CredentialExchange[]|undefined|null> => {
    logger.debug("ACP getCredentialExchangesAsync");
    if (appState.connection) {
      return promises[PromiseTypes.CREDENTIAL_EXCHANGES] ?? fetchCredentialExchange(appState.connection.connection_id!);
    }
    return new Promise(resolve => resolve(undefined));
  }

  // private
  const fetchCredentialExchange = async (connectionId: string): Promise<AcaPyTypes.V10CredentialExchange[]|null> => {
    const promise = StoreWalletApi.ListCredentialExchanges(connectionId)
    .then(res => {
      if (res.data?.results) {
        return res.data?.results ?? null;
      }
      return null;
    })
    .catch(err => {
      return null;
    })
    .finally(() => removePromise(PromiseTypes.CREDENTIAL_EXCHANGES));
    addPromise(PromiseTypes.CREDENTIAL_EXCHANGES, promise);
    return promise;
  }

  const setOIDCCredentialIssuanceRequest = (issuanceRequest: string) => {
    setAppState(oldState => ({...oldState, oidcCredentialIssuanceRequest: issuanceRequest}));
  }

  const createOIDCCredentialIssuanceRequestAsync = async (purchase: Purchase): Promise<string|undefined|null> => {
    const promise = StoreWalletApi.CreateOIDCIssuanceRequest(purchase)
    .then(res => {
      if (res.data) {
        const resData = res.data as EReceiptIssuanceResponse;
        const oidcCredentialIssuanceRequest = resData.issuanceRequest;
        setAppState(oldState => ({...oldState, oidcCredentialIssuanceRequest}));
        return oidcCredentialIssuanceRequest;
      }
      setAppState(oldState => ({...oldState, oidcCredentialIssuanceRequest: null}));
      return null;
    })
    .catch(err => {
      logger.error("ACP createOIDCCredentialIssuanceRequestAsync error", err);
      setAppState(oldState => ({...oldState, walletError: "oidcCredentialIssuanceRequest"}));
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.OIDC_CRED_ISSUANCE_REQ));
    addPromise(PromiseTypes.OIDC_CRED_ISSUANCE_REQ, promise);
    return promise;
  }

  const refreshWalletState = async (): Promise<void> => {
    await loadWalletStateAsync();
  }

  const resetWalletState = async (): Promise<void> => {
    setAppState(() =>({...DefaultAppState, isLoadingApp: false}));
    setRefreshWalletInterval(false);
  }

  const setWalletError = async (error?: string): Promise<void> => {
    setAppState(oldState => ({...oldState, walletError: error}));
  }

  useInterval(async () => {
    if (refreshWalletInterval) {
      refreshWalletState();
    }
  }, refreshWalletInterval || refreshCredExsInterval ? 3000 : null)

  return (
    <AppStateContext.Provider value={{
      ...appState,
      loadAppStateAsync,
      createInvitationAsync,
      createOOBInvitationAsync,
      getCredentialExchangesAsync,
      setConnection,
      getCreatePublicDidConnection,
      // getConnectionAsync,
      setOIDCCredentialIssuanceRequest,
      createOIDCCredentialIssuanceRequestAsync,
      refreshWalletState,
      resetWalletState,
      setWalletError
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export default AppContextProvider;
