import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, TextField, Typography } from '@mui/material';
import { Purchase } from '../models/PurchaseModels';


interface Props {
  purchase: Purchase,
  setPurchase: React.Dispatch<React.SetStateAction<Purchase>>,
  disabled?: boolean
}

const PaymentForm: React.FC<Props> = ({purchase, setPurchase, disabled}) => {
  const { t } = useTranslation(["translation"]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.PaymentForm.title")}
      </Typography>
      { disabled && 
        <Typography variant="body1" gutterBottom sx={{pb: 2}}>
          {t("app.components.PaymentForm.disabledhelpText")}
        </Typography>
      }
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="nameOnCard"
            label={t("app.components.PaymentForm.nameOnCard")}
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            value={purchase.paymentDetails?.nameOnCard}
            onChange={e => setPurchase(old => ({...old, paymentDetails: {...old.paymentDetails, nameOnCard: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label={t("app.components.PaymentForm.cardNumber")}
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            value={purchase.paymentDetails?.cardNumber}
            onChange={e => setPurchase(old => ({...old, paymentDetails: {...old.paymentDetails, cardNumber: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expiryDate"
            label={t("app.components.PaymentForm.expiryDate")}
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            value={purchase.paymentDetails?.expiryDate}
            onChange={e => setPurchase(old => ({...old, paymentDetails: {...old.paymentDetails, expiryDate: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label={t("app.components.PaymentForm.cvv")}
            helperText={t("app.components.PaymentForm.ccvHelpText")}
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            value={purchase.paymentDetails?.cvv}
            onChange={e => setPurchase(old => ({...old, paymentDetails: {...old.paymentDetails, cvv: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PaymentForm;