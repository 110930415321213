import React from 'react';
import { Container, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Purchase } from '../models/PurchaseModels';

interface Props {
  purchase: Purchase,
  setPurchase: React.Dispatch<React.SetStateAction<Purchase>>,
  disabled?: boolean
}

const AddressForm: React.FC<Props> = ({purchase, setPurchase, disabled}) => {
  const { t } = useTranslation(["translation"]);
  
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.AddressForm.title")}
      </Typography>
      { disabled && 
        <Typography variant="body1" gutterBottom sx={{pb: 2}}>
          {t("app.components.AddressForm.disabledhelpText")}
        </Typography>
      }
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label={t("app.components.AddressForm.firstName")}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={purchase.shippingAddress?.firstName}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, firstName: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label={t("app.components.AddressForm.lastName")}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={purchase.shippingAddress?.lastName}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, lastName: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label={t("app.components.AddressForm.address1")}
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            value={purchase.shippingAddress?.addressLine1}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, addressLine1: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label={t("app.components.AddressForm.city")}
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            value={purchase.shippingAddress?.city}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, city: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label={t("app.components.AddressForm.state")}
            fullWidth
            variant="standard"
            value={purchase.shippingAddress?.state}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, state: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label={t("app.components.AddressForm.zip")}
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            value={purchase.shippingAddress?.zipCode}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, zipCode: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label={t("app.components.AddressForm.country")}
            fullWidth
            autoComplete="shipping country"
            variant="standard"
            value={purchase.shippingAddress?.country}
            onChange={e => setPurchase(old => ({...old, shippingAddress: {...old.shippingAddress, country: e.target.value}}))}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddressForm;