import React, { useContext } from "react";
import { AppStateContext } from "../state/AppStateContext";
import Layout from "./common/Layout";
import LoadingModal from "./modals/LoadingModal";
import { Trans, useTranslation } from "react-i18next";
import Copyright from "./Copyright";

const Home: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);
  
  return (
    <>
      <Layout title={t("app.frontpage.heading")}>
        <p>{t("app.frontpage.description")}</p>
        <p>{t("app.frontpage.description2")}</p>
        <p>
          <Trans
            i18nKey="app.frontpage.infotext"
            components={{
              link1: <a href={t("app.frontpage.link")} target="_blank"> Yrityksen digitalous -hanketta</a>
            }}
          />
        </p>
        <LoadingModal isOpen={appContext.isLoadingApp} contentLabel={"Loading"} message={"Loading content..."}/>
      </Layout>
      <Copyright/>
    </>
  );
};

export default Home;
