import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { PaymentDetails, Purchase, ShippingAddress } from '../models/PurchaseModels';

interface Props {
  purchase: Purchase
}

const Review: React.FC<Props> = ({purchase}) => {
  const { t } = useTranslation(["translation"]);

  const getTotalPrice = () => {
    function getSum(total: string, num: string) {
      const sum = parseFloat(total) + parseFloat(num);
      return sum.toFixed(2);
    }
    return purchase.items.map(p => p.product.grossPrice).reduce(getSum, "0");
  }

  const getAddress = () => {
    let addressCombined = !!purchase.shippingAddress?.addressLine1 ? `${purchase.shippingAddress?.addressLine1} ` : "";
    addressCombined = addressCombined + (!!purchase.shippingAddress?.zipCode ? `${purchase.shippingAddress?.zipCode} ` : "");
    addressCombined = addressCombined + (!!purchase.shippingAddress?.city ? `${purchase.shippingAddress?.city} ` : "");
    addressCombined = addressCombined + (!!purchase.shippingAddress?.state ? `${purchase.shippingAddress?.state} ` : "");
    addressCombined = addressCombined + (!!purchase.shippingAddress?.country ? `${purchase.shippingAddress?.country} ` : "");
    return addressCombined;
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.Review.title")}
      </Typography>
      <List disablePadding>
        {purchase.items.map((item) => (
          <ListItem key={item.product.sku} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={t(`app.components.products.${item.product.sku}.name`)} secondary={t(`app.components.products.${item.product.sku}.desc`)} />
            <Typography variant="body2">{item.product.grossPrice} €</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={t("app.components.Review.total")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getTotalPrice()} €
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {t("app.components.AddressForm.title")}
          </Typography>
          <Typography gutterBottom>{purchase.shippingAddress?.firstName} {purchase.shippingAddress?.lastName}</Typography>
          <Typography gutterBottom>{getAddress()}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={7}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {t("app.components.PaymentForm.title")}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography gutterBottom>{t("app.components.PaymentForm.nameOnCard")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{purchase.paymentDetails?.nameOnCard}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{t("app.components.PaymentForm.cardNumber")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{purchase.paymentDetails?.cardNumber}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{t("app.components.PaymentForm.expiryDate")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{purchase.paymentDetails?.expiryDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{t("app.components.PaymentForm.cvv")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{purchase.paymentDetails?.cvv}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Review;