import React from "react";
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import Checkout from "./Checkout";
import Copyright from "./Copyright";

const NewOrder: React.FC = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <Checkout />
      <Copyright />
    </>
  );
}

export default NewOrder;